@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-ExtraBold.eot');
    src: url('../fonts/Rawline-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-ExtraBold.woff2') format('woff2'),
    url('../fonts/Rawline-ExtraBold.woff') format('woff'),
    url('../fonts/Rawline-ExtraBold.ttf') format('truetype'),
    url('../fonts/Rawline-ExtraBold.svg#Rawline-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-SemiBold.eot');
    src: url('../fonts/Rawline-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-SemiBold.woff2') format('woff2'),
    url('../fonts/Rawline-SemiBold.woff') format('woff'),
    url('../fonts/Rawline-SemiBold.ttf') format('truetype'),
    url('../fonts/Rawline-SemiBold.svg#Rawline-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.about_smus {
    width: 100%;
    /*margin-bottom: 57px;*/
}
.about_smus_page_wrapper {
    width: 100%;
}

.about_smus_items_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;


}

.about_smus_items_main_wrapper {
    width: 100%;
    border-top: 0.8px solid #D4E4F2;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 40px;
}

.parsedHtml1 h1 {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1 h1 span {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1_active h1 {
    color:#C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1_active h1 span {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1 img {
    width: 100% !important;
    height: 399px;
    margin-bottom: 20px;
    object-fit: cover;
}
.parsedHtml1_active img {
    width: 100% !important;
    height: 399px;
    margin-bottom: 20px;
    object-fit: cover;
}
.parsedHtml1 h3 {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1 h3 span {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1 p {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1 p span {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1 span {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
}
.about_smus_page_contacts {
    width: 100%;
    margin-bottom: 32px;
}
.parsedHtml1_active h3 {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1_active h3 span {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1_active p {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1_active p span {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1_active span {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
}

.about_smus_page_contacts_title {
    color: rgba(47, 51, 58, 0.85);
    font-family: 'Rawline', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 35px;
}
.about_smus_page_contacts_item_name {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 5px;
}
.about_smus_page_contacts_item_phone {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
}
.about_smus_page_contacts_item_email {
    color: #E71E24;
    font-family: 'Rawline', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.about_smus_page_contacts_item_email span {
    color: #2F333A;
}
.about_smus_page_contacts_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.about_smus_page_item2 {
    max-width: 259px;
    width: 100%;
    padding-top: 41px;
    padding-bottom: 40px;
}

.event_announcements_news_items_wrapper {
    margin-bottom: 41px;
    width: 100%;
}

.last_news_items_wrapper {
    width: 100%;
}
.event_announcement_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 27px;
}

.last_news_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 27px;
}
.event_announcements_news_item {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D4E4F2;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    text-align: left;
}

.event_announcements_news_item:not(:last-child) {
    margin-bottom: 24px;
}
.event_announcements_news_item_img  {
    width: 100%;
    height: 152px;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.event_announcements_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.event_announcements_news_item_info_box {
    padding: 16px 19px;
}

.event_announcements_news_item_info {
    color: #333646;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 16px;
}
.event_announcements_news_item_date {
    color: #E71E24;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.last_news_item {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D4E4F2;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    text-align: left;

}

.last_news_item:not(:last-child) {
    margin-bottom: 24px;
}
.last_news_item_img {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    height: 152px;
    position: relative;
}
.last_news_fav_icon {
    position: absolute;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background: #E71E24;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    left: 19px;
    top: 19px;
    z-index: 999;
}
.last_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.last_news_item_info_box {
    width: 100%;
    padding: 16px 19px;
}
.last_news_item_info {
    color: #333646;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}



.about_smus_page_other_news_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 0 auto;
}
.about_smus_page_other_news_item {
    width: 32%;
    border-radius: 8px;
    background: rgba(26, 18, 39, 0.50);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    position: relative;
    border: none;
    text-align: left;
}

.about_smus_page_other_news_item_img {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

}
.about_smus_page_other_news_item_img_child {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}
.about_smus_page_other_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about_smus_page_other_news_item_info_box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    padding: 24px 30px;

}

.about_smus_page_other_news_item_title {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 130px;
}


.about_smus_page_other_news_item_info {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    max-width: 271px;
    width: 100%;
}

.about_smus_item1 {
    width: 100%;
    padding-top: 44px;
    padding-bottom: 40px;
    max-width: 800px;
    border-right: 1px solid #D4E4F2;
    padding-right: 38px;
}

.about_smus_page_links_title_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

}

.about_smus_page_link {
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    /*margin-right: 19px;*/
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}
.privacy_policy_page_link_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.privacy_policy_page_main_title {
    color: #2F333A;
    font-family: 'Evolventa',sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 40px;
    border-bottom: 0.8px solid #D4E4F2;
    padding-bottom: 20px;
}


.privacy_policy_info_items_wrapper {
    width: 100%;
}

.privacy_policy_info_item {
    width: 100%;
}
.privacy_policy_info_item_title {
    color: #2F333A;
    font-family: 'Evolventa',sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 120% */
    margin-bottom: 35px;
}
.privacy_policy_info_item_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 25px;
}
.smus_command {
    width: 100%;
    margin-bottom: 10px;
}
.smus_command_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 22.8px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.smus_commands_tab_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.smus_commands_tab_item {
    border-radius: 5px;
    border: 1px solid #D4E4F2;
    width: 23.6%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2F333A;
    text-align: center;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: none;
    outline: none;
    cursor: pointer;
}
.smus_commands_tab_item:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(231, 30, 36);
    font-weight: 700;
    border: 1px solid rgb(231, 30, 36);
}
.smus_commands_members_items_wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;
}
.smus_commands_members_item {
    width: 31%;
    margin-bottom: 50px;
}
.smus_commands_members_item_avatar {
    width: 148px;
    height: 148px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
}
.smus_commands_members_item_avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.smus_commands_members_item_avatar_icon {
    width: 54.468px;
    height: 54.468px;
    position: absolute;
    z-index: 999;
    left: 0;
    bottom: 8px;
}
.smus_commands_members_item_name {
    color: #2F333A;
    text-align: center;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 10px;
}
.smus_commands_members_item_text1 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    width: 120px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.smus_commands_members_item_text2 {
    color: #AA5338;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    text-align: center;
}

/*media*/
@media (min-width: 426px) and (max-width: 1120px) {
    .about_smus_page_other_news_items_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .about_smus_items_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media (max-width: 426px) {
    .about_smus_page_other_news_items_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px;
        padding-left: 30px;
    }
    .about_smus_items_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-right: 30px;
        padding-left: 30px;
    }
}


@media (min-width: 521px) and (max-width: 768px) {
    .about_smus_page_other_news_items_wrapper {
        flex-wrap: wrap;
    }
    .about_smus_page_other_news_item {
        width: 48%!important;
    }
    .about_smus_page_other_news_item:not(:last-child) {
        margin-bottom: 22px;
    }
}
@media  (max-width: 521px) {
    .about_smus_page_other_news_items_wrapper {
        flex-wrap: wrap;
    }
    .about_smus_page_other_news_item {
        width: 100%!important;
    }
    .about_smus_page_other_news_item:not(:last-child) {
        margin-bottom: 22px;
    }
}

@media (min-width: 426px) and (max-width: 900px) {
    .about_smus_page_other_news_item_info {
        font-size: 18px!important;
    }
}
@media (min-width: 426px) and (max-width: 769px) {
    .about_smus_page_other_news_item_title {
        font-size: 20px!important;
    }
    .parsedHtml1 h1, h2, h3, h4, h5, h6 {
        font-size: 28px!important;
    }
    .parsedHtml1 h1, h2, h3, h4, h5, h6 span{
        font-size: 28px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 {
        font-size: 28px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 span{
        font-size: 28px!important;
    }
    .event_announcements_news_item_img {
        height: 300px!important;
    }
    .last_news_item_img {
        height: 300px!important;
    }

}
@media (max-width: 426px) {
    .parsedHtml1 h1, h2, h3, h4, h5, h6 {
        font-size: 21px!important;
    }
    .parsedHtml1 h1, h2, h3, h4, h5, h6 span {
        font-size: 21px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 {
        font-size: 21px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 span {
        font-size: 21px!important;
    }
    .privacy_policy_page_links_title_wrapper {
        margin-bottom: 18px!important;
    }
    .about_smus_page_link {
        font-size: 12px!important;
        margin-right: 16px!important;
    }
    .about_smus_page_link_title {
        font-size: 12px!important;
    }
    .about_smus_item1 {
        padding-top: 34px!important;
    }
    /*.privacy_policy_page {*/
    /*    margin-bottom: 45px!important;*/
    /*}*/
    .last_news_item_info {
        font-size: 15px!important;
    }
    .last_news_item_info_box {
        padding: 12px 15px!important;
    }
    .last_news_item_img {
        height: 175px!important;
    }

    .last_news_title {
        font-size: 12px!important;
        margin-bottom: 22px!important;
    }
    .event_announcements_news_item_date {
        font-size: 12px!important;
    }
    .event_announcements_news_item_info {
        font-size: 15px!important;
        margin-bottom: 14px!important;
    }
    .event_announcements_news_item_info_box {
        padding: 12px 15px!important;
    }
    .event_announcements_news_item_img {
        height: 175px!important;
    }
    .event_announcement_title {
        font-size: 12px!important;
        margin-bottom: 22px!important;
    }
    .about_smus_page_other_news_item_info {
        font-size: 15.6px!important;
    }
    .about_smus_page_other_news_item_img {
        height: 240px!important;
    }
    .about_smus_page_other_news_item_info_box {
        padding: 19px 20px!important;
    }
    .about_smus_page_other_news_item_title {
        font-size: 12px!important;
        margin-bottom: 100px!important;
    }
}

@media (min-width: 769px) and (max-width: 1120px) {

    .about_smus_item1 {
        max-width: unset!important;
        width: 67%!important;
    }

    .about_smus_page_item2 {
        max-width: unset!important;
        width: 30%!important;

    }
}

@media (max-width: 768px) {
    .about_smus_items_wrapper {
        flex-direction: column;
    }
    .about_smus_item1 {
        max-width: unset!important;
        width: 100%!important;
        border-right: unset!important;
        padding-right: unset!important;
        margin-bottom: 50px;
        padding-bottom: unset!important;
    }

    .about_smus_page_item2 {
        max-width: unset!important;
        width: 100%!important;
        padding-top: unset!important;

    }
}


.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}

@media (min-width: 959px) and (max-width: 3000px) {
    .smus_commands_members_item:not(:nth-child(3n)) {
        margin-right: 20px!important;
    }
}

@media (min-width: 868px) and (max-width: 959px) {
    .smus_commands_members_item:not(:nth-child(3n)) {
        margin-right: 18px!important;
    }
}
@media (min-width: 768px) and  (max-width: 868px) {
    .smus_commands_members_item {
        width: 48% !important;
    }
    .smus_commands_members_items_wrapper {
       justify-content: space-between!important;
    }
}
@media (min-width: 601px) and (max-width: 768px) {
    .smus_commands_members_item:not(:nth-child(3n)) {
        margin-right: 20px!important;
    }
}

@media (min-width: 426px) and (max-width: 601px) {
    .smus_commands_members_item {
        width: 48% !important;
    }
    .smus_commands_members_items_wrapper {
        justify-content: space-between!important;
    }
}
@media (min-width: 426px) and (max-width: 768px) {
    .parsedHtml1 img {
        height: 300px!important;
    }
    .parsedHtml1_active img {
        height: 300px!important;
    }
}
@media (min-width: 375px) and  (max-width: 426px) {
    .parsedHtml1 img {
        height: 200px!important;
        margin-bottom: unset!important;
    }
    .parsedHtml1_active img {
        height: 200px!important;
        margin-bottom: unset!important;
    }
}
@media  (max-width: 375px) {
    .parsedHtml1 img {
        height: 150px!important;
        margin-bottom: unset!important;
    }
    .parsedHtml1_active img {
        height: 150px!important;
        margin-bottom: unset!important;
    }
}

@media (max-width: 426px) {

    .smus_command_title {
        font-size: 18px!important;
    }
    .parsedHtml1 h3 {
        font-size: 18px!important;
    }
    .parsedHtml1 p {
        font-size: 15px!important;
        margin-bottom: 22px!important;
    }
    .parsedHtml1 p span {
        font-size: 15px!important;
        margin-bottom: 22px!important;
    }
    .parsedHtml1 h1 {
        margin-bottom: 34px!important;
    }
    .about_smus_page_contacts_item_name {
        font-size: 16px!important;
    }
    .about_smus_page_contacts_title {
        font-size: 18px!important;
        margin-bottom: 30px!important;
    }
    .mobile_smus_commands_members_items_wrapper_parent {
        margin-top: 30px!important;
        display: block!important;
    }
    .smus_commands_members_items_wrapper {
        display: none!important;
    }
    .smus_commands_tab_items_wrapper {
        flex-wrap: wrap;
    }
    .smus_commands_tab_item {
        width: 48% !important;
        margin-bottom: 11px;
    }
    .smus_commands_members_item {
        width: 100% !important;
        padding-bottom: 15px!important;
    }
    .smus_commands_members_items_wrapper {
        justify-content: space-between!important;
    }
    .about_smus_page_contacts_items_wrapper {
        flex-wrap: wrap;
    }
    .about_smus_page_contacts_item {
        width: 100% !important;

    }
    .about_smus_page_contacts_item:first-child {
        margin-bottom: 30px!important;
    }
}



.mobile_smus_commands_members_items_wrapper_parent {
    display: none;
}


.swiper-pagination-bullet {
    border-radius: 17.65px!important;
    background: rgba(217, 217, 217, 0.32)!important;
    width: 18px!important;
    height: 18px!important;
    opacity: 1!important;
}


.swiper-pagination-bullet-active {
    background: #E71E24!important;
}
