@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-SemiBold.eot');
    src: url('../fonts/Rawline-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-SemiBold.woff2') format('woff2'),
    url('../fonts/Rawline-SemiBold.woff') format('woff'),
    url('../fonts/Rawline-SemiBold.ttf') format('truetype'),
    url('../fonts/Rawline-SemiBold.svg#Rawline-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}




* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.news_single_page {
    width: 100%;
    /*padding-bottom: 57px;*/
}
.news_single_page_wrapper {
    width: 100%;
}

.news_single_page_items_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;


}

.news_single_page_items_main_wrapper {
    width: 100%;
    border-top: 0.8px solid #D4E4F2;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 40px;
}

.news_single_page_item2 {
    max-width: 259px;
    width: 100%;
    padding-top: 41px;
    padding-bottom: 40px;
}

.event_announcements_news_items_wrapper {
    margin-bottom: 41px;
    width: 100%;
}

.last_news_items_wrapper {
    width: 100%;
}
.event_announcement_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 27px;
}

.last_news_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 27px;
}
.event_announcements_news_item {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D4E4F2;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    text-align: left;
}

.event_announcements_news_item:not(:last-child) {
    margin-bottom: 24px;
}
.event_announcements_news_item_img  {
    width: 100%;
    height: 152px;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.event_announcements_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.event_announcements_news_item_info_box {
    padding: 16px 19px;
}

.event_announcements_news_item_info {
    color: #333646;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 16px;
}
.event_announcements_news_item_date {
    color: #E71E24;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.last_news_item {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D4E4F2;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    text-align: left;
}

.last_news_item:not(:last-child) {
    margin-bottom: 24px;
}
.last_news_item_img {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    height: 152px;
    position: relative;
}
.last_news_fav_icon {
    position: absolute;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background: #E71E24;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    left: 19px;
    top: 19px;
    z-index: 999;
}
.last_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.last_news_item_info_box {
    width: 100%;
    padding: 16px 19px;
}
.last_news_item_info {
    color: #333646;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}



.news_single_page_other_news_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 0 auto;
}
.news_single_page_other_news_item {
    width: 32%;
    border-radius: 8px;
    background: rgba(26, 18, 39, 0.50);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    position: relative;
    border: none;
    text-align: left;
}

.news_single_page_other_news_item_img {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
.news_single_page_other_news_item_img_child {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}
.news_single_page_other_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news_single_page_other_news_item_info_box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    padding: 24px 30px;

}

.news_single_page_other_news_item_title {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 130px;
}


.news_single_page_other_news_item_info {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    max-width: 271px;
    width: 100%;
}

.news_single_page_item1 {
    width: 100%;
    padding-top: 44px;
    padding-bottom: 40px;
    max-width: 800px;
    border-right: 1px solid #D4E4F2;
    padding-right: 38px;
}

.news_single_page_links_title_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 22px;

}

.news_single_page_link {
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    /*margin-right: 19px;*/
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.news_single_page_link_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.news_single_page_main_title {
    color: #2F333A;
    font-family: 'Evolventa',sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 29px;
}

.news_single_page_main_img {
    width: 100%;
    height: 383px;
    margin-bottom: 30px;
}
.news_single_page_main_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news_single_page_parsed_html_wrapper {
    width: 100%;
}
.news_single_page_parsed_html_wrapper_dark {
    width: 100%;
}
.news_single_page_parsed_html_wrapper p {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 25px;
}
.news_single_page_parsed_html_wrapper_dark p {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 25px;
}

.news_single_page_parsed_html_wrapper img {
    width: 100%;
    max-width: 374px;
    height: 249px;
    object-fit: cover;
    margin-bottom: 20px;
    display: inline-block;
}

.news_single_page_parsed_html_wrapper_dark img {
    width: 100%;
    max-width: 374px;
    height: 249px;
    object-fit: cover;
    margin-bottom: 20px;
    display: inline-block;
}


@media (min-width: 426px) and (max-width: 1120px) {
    .news_single_page_items_wrapper {
        width: 95% !important;
        max-width: unset!important;
    }
    .news_single_page_other_news_items_wrapper {
        width: 95% !important;
        max-width: unset!important;
    }
}
@media  (max-width: 426px) {
    .news_single_page_items_wrapper {
        width: 100%!important;
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .news_single_page_other_news_items_wrapper {
        width: 100%!important;
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 769px) and (max-width: 1120px) {
    .news_single_page_item1 {
        max-width: unset!important;
        width: 67% !important;
    }
    .news_single_page_item2 {
        max-width: unset!important;
        width: 30% !important;
    }
}

@media (min-width: 426px) and (max-width: 900px) {
    .news_single_page_other_news_item_info {
        font-size: 18px!important;
    }
}
@media (min-width: 521px) and (max-width: 768px) {
    .news_single_page_other_news_items_wrapper {
        flex-wrap: wrap;
    }
    .news_single_page_other_news_item {
        width: 48%!important;
    }
    .news_single_page_other_news_item:not(:last-child) {
        margin-bottom: 22px;
    }
}
@media  (max-width: 521px) {
    .news_single_page_other_news_items_wrapper {
        flex-wrap: wrap;
    }
    .news_single_page_other_news_item {
        width: 100%!important;
    }
    .news_single_page_other_news_item:not(:last-child) {
        margin-bottom: 22px;
    }
}
@media  (max-width: 426px) {
    .news_single_page_item2 {
        padding-top: unset!important;
    }
    .news_single_page_item1 {
        padding-top: 34px!important;
    }
    /*.news_single_page {*/
    /*    padding-bottom: 45px!important;*/
    /*}*/
    .last_news_title {
        font-size: 12px!important;
        margin-bottom: 22px!important;
    }
    .last_news_item_info {
        font-size: 15px!important;
    }
    .last_news_item_info_box {
        padding: 12px 15px!important;
    }
    .news_single_page_items_wrapper {
        flex-direction: column;
    }
    .news_single_page_item1 {
        max-width: unset!important;
        width: 100% !important;
        border-right: unset!important;
        padding-right: unset!important;
    }
    .news_single_page_link {
        font-size: 12px!important;
    }
    .news_single_page_link_title {
        font-size: 12px!important;
    }
    .news_single_page_links_title_wrapper {
        margin-bottom: 18px!important;
    }
    .news_single_page_main_title {
        font-size: 21px!important;
        margin-bottom: 23px!important;
    }
    .news_single_page_item2 {
        max-width: unset!important;
        width: 100% !important;
    }
    .last_news_item_img {
        height: 175px!important;
    }
    .event_announcements_news_item_img {
        height: 175px!important;
    }
    .event_announcements_news_item_info_box {
        padding: 12px 15px!important;
    }
    .event_announcements_news_item_info {
        font-size: 15px!important;
        margin-bottom: 14px!important;
    }
    .event_announcements_news_item_date {
        font-size: 12px!important;
    }
    .event_announcements_news_item:not(:last-child) {
        margin-bottom: 20px!important;
    }
    .event_announcement_title {
        font-size: 12px!important;
        margin-bottom: 22px!important;
    }
    .news_single_page_other_news_item_img {
        height: 240px!important;
    }
    .news_single_page_other_news_item_img_child {
        height: 240px!important;
    }
    .news_single_page_other_news_item_info {
        font-size: 15.6px!important;
    }
    .news_single_page_other_news_item_title {
        font-size: 12px!important;
        margin-bottom: 100px!important;
    }
    .news_single_page_other_news_item_info_box {
        padding: 19px 20px!important;
    }
}

@media (min-width: 426px) and (max-width: 769px) {
    .news_single_page_main_title {
        font-size: 28px!important;
        margin-bottom: 23px!important;
    }
    .news_single_page_item2 {
        padding-top: unset!important;
    }
    .news_single_page_items_wrapper {
        flex-direction: column;
    }
    .news_single_page_item1 {
        max-width: unset!important;
        width: 100% !important;
        margin-bottom: 45px;
        border-right: unset!important;
        padding-right: unset!important;
    }
    .news_single_page_item2 {
        max-width: unset!important;
        width: 100% !important;
    }
    .event_announcements_news_item_img {
        height: 300px!important;
    }
    .last_news_item_img {
        height: 300px!important;
    }


}

.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}



.parsedHtml1 h1 {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1 h1 span {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1_active h1 {
    color:#C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1_active h1 span {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml1 img {
    width: 100% !important;
    height: 399px;
    margin-bottom: 20px;
    object-fit: cover;
}
.parsedHtml1_active img {
    width: 100% !important;
    height: 399px;
    margin-bottom: 20px;
    object-fit: cover;
}
.parsedHtml1 h3 {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1 h3 span {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1 p {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1 p span {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1 span {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
}
.about_smus_page_contacts {
    width: 100%;
    margin-bottom: 32px;
}
.parsedHtml1_active h3 {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1_active h3 span {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml1_active p {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1_active p span {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml1_active span {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
}

@media (min-width: 426px) and (max-width: 769px) {
    .parsedHtml1 h1, h2, h3, h4, h5, h6 {
        font-size: 28px!important;
    }
    .parsedHtml1 h1, h2, h3, h4, h5, h6 span{
        font-size: 28px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 {
        font-size: 28px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 span{
        font-size: 28px!important;
    }
    .event_announcements_news_item_img {
        height: 300px!important;
    }
    .last_news_item_img {
        height: 300px!important;
    }

}
@media (max-width: 426px) {
    .parsedHtml1 h1, h2, h3, h4, h5, h6 {
        font-size: 21px!important;
    }
    .parsedHtml1 h1, h2, h3, h4, h5, h6 span {
        font-size: 21px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 {
        font-size: 21px!important;
    }
    .parsedHtml1_active h1, h2, h3, h4, h5, h6 span {
        font-size: 21px!important;
    }

}


@media (min-width: 426px) and (max-width: 768px) {
    .parsedHtml1 img {
        height: 300px!important;
    }
    .parsedHtml1_active img {
        height: 300px!important;
    }
}
@media (min-width: 375px) and  (max-width: 426px) {
    .parsedHtml1 img {
        height: 200px!important;
        margin-bottom: unset!important;
    }
    .parsedHtml1_active img {
        height: 200px!important;
        margin-bottom: unset!important;
    }
}
@media  (max-width: 375px) {
    .parsedHtml1 img {
        height: 150px!important;
        margin-bottom: unset!important;
    }
    .parsedHtml1_active img {
        height: 150px!important;
        margin-bottom: unset!important;
    }
}
@media (min-width: 521px) and (max-width: 768px) {
    .banner_img {
        height: 270px!important;
    }
    .banner_title {
        font-size: 48px!important;
    }
}
@media (min-width: 426px) and (max-width: 521px) {
    .banner_img {
        height: 180px!important;
    }
    .banner_title {
        font-size: 45px!important;
    }
}

@media (max-width: 426px) {
    .banner_img {
        height: 95px!important;
    }
    .banner_title {
        display: none!important;
    }

    .parsedHtml1 h3 {
        font-size: 18px!important;
    }
    .parsedHtml1 p {
        font-size: 14px!important;
        margin-bottom: 22px!important;
    }
    .parsedHtml1 p span {
        font-size: 14px!important;
        margin-bottom: 22px!important;
    }
    .parsedHtml1 h1 {
        margin-bottom: 34px!important;
    }

}

.banner_box {
    padding-bottom: 30px;
    border-bottom: 1px solid #E3E4EA;
    margin-bottom: 25px;
    width: 100%;

}

.banner_img {
    width: 100%;
    height: 373px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}
.banner_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.banner_title {
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 61px;
    font-style: normal;
    font-weight: 700;
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, 37%);
}

.get_random_for_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
    flex-wrap: wrap;
}
.get_random_for_item {
    width: 48%;
    margin-bottom: 25px;
    border: 1px solid #D4E4F2;
    padding: 23px;
    border-radius: 8px;
}

.get_random_for_item_title {
    margin-bottom: 22px;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #2F333A;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
}

.get_random_for_item_info {
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0em;
    margin-bottom: 19px;
    color: #2F333A;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
}

.get_random_for_item_date_likes_info_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.get_random_for_item_like_icon_info_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.get_random_for_item_date_info {
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #2F333A;
}

.get_random_for_item_like_icon_info {
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #2F333A;
    padding-left: 8px;
}

.get_random_for_item_like_icon {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categories_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #E3E4EA;
    padding-bottom: 25px;
    margin-bottom: 25px;
    flex-wrap: wrap;

}


.categories_item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 17%;
    margin-right: 20px;
}

.categories_item_icon {
    margin-right: 7px;
}
.categories_item_title {
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #2F333A;
}


@media (min-width: 461px) and (max-width: 970px) {
    .get_random_for_item_info {
        font-size: 17px!important;
    }
    .categories_item_title {
        font-size: 13px!important;
    }
}

@media (min-width: 426px) and (max-width: 460px) {
    .categories_item_icon svg {
        width: 12px!important;
        height: 12px!important;
    }
    .categories_item_icon {
        margin-right: 5px!important;
    }
    .get_random_for_item_info {
        font-size: 15px!important;
    }
    .categories_item_title {
        font-size: 12px!important;
    }
}

@media (min-width: 769px) and (max-width: 1100px) {
    .categories_item {
        width: 20% !important;
    }
}
@media (min-width: 460px) and  (max-width: 700px) {
    .categories_item {
        width: 20% !important;
    }
}
@media (max-width: 460px) {
    .news_single_page_item1 {
        padding-bottom: 25px!important;
    }
    .categories_items_wrapper {
        justify-content: space-between!important;
    }
    .categories_item {
        width: 33%;
        margin-right: unset!important;
        justify-content: center!important;
    }
    .get_random_for_item_title {
        margin-bottom: 18px!important;
        font-size: 12px!important;
    }
    .categories_items_wrapper {
        padding-bottom: 0px!important;
        margin-bottom: 20px!important;
    }
    .banner_box {
        margin-bottom: 20px!important;
        padding-bottom: 25px!important;
    }
    .categories_item_icon svg {
        width: 12px!important;
        height: 12px!important;
    }
    .get_random_for_item_like_icon_info {
        font-size: 14px!important;
        padding-left: 5px!important;
    }
    .get_random_for_item_date_info {
        font-size: 12px!important;
    }
    .categories_item_icon {
        margin-right: 5px!important;
    }
    .get_random_for_item_info {
        font-size: 15px!important;
    }
    .categories_item_title {
        font-size: 10px!important;
    }
}


@media (max-width: 550px) {
    .get_random_for_item {
        width: 100% !important;
        margin-bottom: 18px!important;
        padding: 20px!important;
    }
}

