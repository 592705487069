@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.publication_feeds {
    width: 100%;
    padding-bottom: 57px;
    border-top: 0.8px solid #D4E4F2;
    padding-top: 44px;
}
.publication_feeds_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.publication_feeds_link_titles_info_wrapper {
    width: 100%;
}
.publication_feeds_link_titles_info_wrapper_child1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 23px;
}
.publication_feeds_link_titles_info_wrapper_child2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
    padding-bottom: 17px;
    border-bottom: 1px solid #D4E4F2;
}
.publication_feeds_title_main {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 32.3px;
    font-style: normal;
    font-weight: 700;
}
.publication_feeds_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
}
.publication_feeds_link {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    /*margin-right: 16px;*/
    outline: none;
    cursor: pointer;
    display: flex;
}

.publication_feeds_title {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
}
.publication_feeds_items_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 40px;
}
.publication_feeds_item {
    width: 30.9%;
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    min-height: 360px;
    margin-bottom: 30px;
}

.publication_feeds_item_img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;

}
.publication_feeds_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s ease-in-out;
}

.publication_feeds_item_info_box {
    width: 100%;
    padding: 19px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.publication_feeds_item_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.65px;
    margin-bottom: 23px;
    height: 90px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    text-align: left;
}

.publication_feeds_item_date_likes_info_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.publication_feeds_item_date_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}
.publication_feeds_item_like_icon_info_wrapper {
    display: flex;
    align-items: center;
}
.publication_feeds_item_like_icon {
    display: flex;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
}
.publication_feeds_item_like_icon_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-left: 8px;
}
.publication_feeds_load_more_btn {
    border-radius: 6px;
    background: #E71E24;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    width: 288px;
    height: 68px;
    margin: 0 auto;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
}
.publication_feeds_load_more_btn:hover {
    background: #D00000;
}

.publication_feeds_load_more_btn span {
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*send_news*/

.send_news {
    width: 100%;
    background: #2F333A;
    padding: 60px 0;
    /*padding-bottom: 57px;*/
}
.send_news_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.send_news_items_wrapper {
    width: 100%;
}


.send_news_img_items_wrapper {
    width: 100%;
    max-width: 540px;
}
.send_news_img_item {
    width: 100%;
    height: 363px;
    border-radius: 6px;
    overflow: hidden;
}
.send_news_img_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.send_news_img_item:first-child {
    margin-bottom: 20px;
}
.send_news_title {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 38px;
}

.send_news_form {
    width: 100%;
    max-width: 540px;
}

.send_news_form_input_wrapper {
    width: 100%;
    margin-bottom: 22px;
}
.send_news_form_input_field {
    border-radius: 6px;
    border: 1.5px solid #FFF;
    background: #FFF;
    width: 100%;
    padding: 20px;
    cursor: pointer;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.send_news_form_input_field::placeholder {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.send_news_form_textarea {
    border-radius: 6px;
    border: 1.5px solid #FFF;
    background: #FFF;
    width: 100%;
    padding: 20px;
    cursor: pointer;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    min-height: 280px;
    resize: none;
}
.send_news_form_textarea::placeholder {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.send_news_info {
    color: #8C8C8C;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;

}
.send_news_privacy_policy_link {
    color: #FFFFFF;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    display: inline-block;
    margin-left: 2px;
}

.send_news_form_btn {
    border-radius: 6px;
    opacity: 0.9;
    background: #E71E24;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 30.4px; /* 160% */
    width: 100%;
    border: none;
    height: 68px;
}

.send_news_form_attach_a_file_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.send_news_form_attach_a_file_title {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-left: 15px;
}

.send_news_form_textarea_wrapper {
    width: 100%;
    margin-bottom: 12px;
}

@media (min-width: 768px) and (max-width: 1120px) {
    .send_news_form {
        width: 48% !important;
        max-width: unset!important;
    }
    .send_news_img_items_wrapper {
        width: 48% !important;
        max-width: unset!important;
    }
}
@media  (max-width: 768px) {
    .send_news_items_wrapper {
        flex-direction: column;
    }
    .send_news_form {
        width: 100% !important;
        max-width: unset!important;
        margin-bottom: 31px;
    }
    .send_news_img_items_wrapper {
        width: 100% !important;
        max-width: unset!important;
    }
}



@media (min-width: 426px) and (max-width: 1120px) {
    .send_news_wrapper {
        max-width: unset !important;
        width: 95% !important;
    }
    .publication_feeds_wrapper {
        max-width: unset !important;
        width: 95% !important;
    }
}


@media (min-width: 426px) and (max-width: 975px) {
    .publication_feeds_item_title {
        font-size: 17px !important;
    }
    .publication_feeds_item_info_box {
        padding: 15px!important;
    }

}


@media (min-width: 521px) and (max-width: 768px) {
    .publication_feeds_item {
        width: 48% !important;
    }
}
@media  (max-width: 521px) {
    .publication_feeds_item {
        width: 100% !important;
        min-height: unset!important;
    }
    .publication_feeds_link_titles_info_wrapper_child2 {
        flex-direction: column;
        justify-content: flex-start!important;
        align-items: flex-start!important;
    }
    .publication_feeds_item:last-child {
        margin-bottom: unset!important;
    }
}


@media (max-width: 426px) {
    .publication_feeds_load_more_btn {
        font-size: 16px!important;
    }
    .publication_feeds {
        padding-top: 34px!important;
        margin-bottom: 50px!important;
    }
    .publication_feeds_link_titles_info_wrapper_child2 {
        padding-bottom: 14px!important;
    }
    .publication_feeds_info {
        font-size: 15px!important;
    }
    .publication_feeds_link_titles_info_wrapper_child1 {
        margin-bottom: 17px!important;
    }
    .publication_feeds_link {
        font-size: 12px!important;
    }
    .publication_feeds_title {
        font-size: 12px!important;
    }
    .publication_feeds_item:not(:last-child) {
        margin-bottom: 25px!important;
    }
    .publication_feeds_title_main {
        font-size: 25px!important;
        margin-bottom: 10px;
    }
    .publication_feeds_item:last-child {
        margin-bottom: unset!important;
    }
    .send_news_form_textarea {
        font-size: 15px!important;
    }
    .send_news_form_textarea::placeholder {
        font-size: 15px!important;
    }
    .send_news_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .publication_feeds_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    /*.send_news {*/
    /*    padding-bottom: 45px!important;*/
    /*}*/
    .send_news_img_item {
        height: 200px!important;
    }
    .send_news_form_btn {
        font-size: 15px!important;
    }
    .send_news_info {
        font-size: 12px!important;
    }
    .send_news_privacy_policy_link {
        font-size: 12px!important;
    }
    .send_news_form_input_field {
        font-size: 15px!important;
    }
    .send_news_form_input_field::placeholder {
        font-size: 15px!important;
    }
    .send_news_title {
        font-size: 16.5px!important;
        margin-bottom: 30px!important;
    }
    .send_news_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }

    .publication_feeds_item_title {
        font-size: 15px !important;
        margin-bottom: 14px!important;
        height: unset!important;
    }
    .publication_feeds_item_info_box {
        padding: 15px!important;
    }
    .publication_feeds_item_img {
        height: 175px!important;
    }
    .publication_feeds_item_date_info {
        font-size: 13px!important;
    }
}

.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}


@media (min-width: 1120px) and (max-width: 1920px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 40px!important;
    }
}
@media (min-width: 1096px) and (max-width: 1120px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 38px!important;
    }
}
@media (min-width: 1067px) and  (max-width: 1096px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 37px!important;
    }
}
@media  (min-width: 1038px) and (max-width: 1067px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 36px!important;
    }
}
@media (min-width: 1009px) and (max-width: 1038px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 35px!important;
    }
}
@media (min-width: 981px) and  (max-width: 1009px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 34px!important;
    }
}
@media  (min-width: 951px) and  (max-width: 981px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 33px!important;
    }
}
@media   (min-width: 923px) and (max-width: 951px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 32px!important;
    }
}
@media   (min-width: 894px) and (max-width: 923px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 31px!important;
    }
}
@media  (min-width: 865px) and (max-width: 894px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 30px!important;
    }
}
@media  (min-width: 836px) and  (max-width: 865px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 29px!important;
    }
}
@media  (min-width: 808px) and  (max-width: 836px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 28px!important;
    }
}
@media  (min-width: 778px) and (max-width: 808px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 27px!important;
    }
}
@media  (min-width: 769px) and  (max-width: 778px) {
    .publication_feeds_item:not(:nth-child(3n)) {
        margin-right: 26px!important;
    }
}
@media    (max-width: 769px) {
    .publication_feeds_items_wrapper {
        justify-content: space-between!important;
    }
}


.publication_feeds_item:hover .publication_feeds_item_title{
    text-decoration: underline;
}
.publication_feeds_item:hover .publication_feeds_item_img img {
    transform: scale(1.15);
}
