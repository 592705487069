@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}


.main_news {
    width: 100%;
    margin-bottom: 57px;
}
.main_news_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.main_news_item {
    width: 25%;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    position: relative;
    background: none;
    border: none;

}
.main_news_item_img_child {
    width: 100%;
    height: 360px;
    position: absolute;
    top: 0;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.main_news_item_img {
    width: 100%;
    height: 360px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.main_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main_news_item_info_box {
    position: absolute;
    z-index: 9;
    bottom: 38px;
    left: 47px;
    right: 47px;
}


.main_news_item_title {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 21px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    text-align: left;
    position: relative;
    transition: all 0.3s;
}

.main_news_item_date_info_likes_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.main_news_item_like_icon_info_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9999;
}


.main_news_item_like_info {
    color: #FFF;
    font-family: 'Rawline',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
    margin-left: 8px;
}

.main_news_item_date_info {
    color: #FFF;
    font-family: 'Rawline',sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.32px;
}


/*new_ot_the_site_news*/

.new_ot_the_site_news {
    width: 100%;
    margin-bottom: 58px;
}

.new_ot_the_site_news_wrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.new_ot_the_site_news_first_item {
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    width: 100%;
    max-width: 341px;
    padding: 23px;
}

.new_on_the_site_news_second_item {
    max-width: 720px;
    width: 100%;
}


.new_ot_the_site_news_first_item_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 29px;
}

.new_ot_the_site_news_item {
    display: flex;
    cursor: pointer;
    outline: none;
    border-radius: 8px;
    padding: 19px;
    text-decoration: none;
    border: none;
    background: none;

}
.new_ot_the_site_news_item:not(:last-child) {
    margin-bottom: 20px;
}
.new_ot_the_site_news_item_info_box {
    width: 100%;
}
.new_ot_the_site_news_item_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 18.2px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.62px;
    margin-bottom: 22px;
    text-align: left;
}

.new_ot_the_site_news_item_date_hour_info_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.new_ot_the_site_news_item_date_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
}
.new_ot_the_site_news_item_hour_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
}

.new_on_the_site_news_second_item_title_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.new_on_the_site_news_second_item_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
}
.new_on_the_site_news_second_item_btn {
    border-radius: 19px;
    border: 1px solid #E71E24;
    background: #E71E24;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    position: relative;
}

.new_on_the_site_news_second_item_btn:hover {
    background: #D00000;
}
.news_articles_btn:hover {
    background: #D00000;
}
.news_articles_btn2:hover {
    background: #D00000;
}
.new_on_the_site_news_second_item_btn svg {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.new_on_the_site_news_second_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.new_on_the_site_news_item2 {
    width: 48%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    margin-bottom: 39px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    min-height: 360px;
}

.new_on_the_site_news_item_img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;

}
.new_on_the_site_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s ease-in-out;
}

.new_on_the_site_news_item2_info_box {
    background-color: #FFFFFF;
    padding: 19px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
}

.new_on_the_site_news_item2_info_box_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 23px;
    height: 105px;
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    border: none;
    position: relative;
    text-align: left;
}
.new_on_the_site_news_item2_date_hour_info_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.new_on_the_site_news_item2_date_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}

.new_on_the_site_news_item_like_icon_info_wrapper {
    display: flex;
    align-items: center;
}
.new_on_the_site_news_item_like_icon_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-left: 8px;
}

/*news_articles*/

.news_articles {
    width: 100%;
    margin-bottom: 57px;
    padding: 57px 0;
    background: #2F333A;
}

.news_articles_wrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}

.news_articles_title_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.news_articles_title {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
}
.news_articles_btn {
    border-radius: 19px;
    background: #E71E24;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
    position: relative;
}
.news_articles_btn svg {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.news_articles_items_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}
.news_articles_item {
    width: 30.9%;
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    min-height: 360px;
    overflow: hidden;

}
.news_articles_item_img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}
.news_articles_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s ease-in-out;
}

.news_articles_item_info_box {
    width: 100%;
    padding: 19px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news_articles_item_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.65px;
    margin-bottom: 23px;
    height: 90px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    text-align: left;
}

.news_articles_item_date_likes_info_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.news_articles_item_date_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}
.news_articles_item_like_icon_info_wrapper {
    display: flex;
    align-items: center;
}
.news_articles_item_like_icon_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-left: 8px;
}

/*news_articles2*/

.news_articles2 {
    width: 100%;
    margin-bottom: 65px;
}

.news_articles_wrapper2 {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}

.news_articles_title_btn_wrapper2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.news_articles_title2 {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
}
.news_articles_btn2 {
    border-radius: 19px;
    background: #E71E24;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
    position: relative;
}
.news_articles_btn2 svg {
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.news_articles_items_wrapper2 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}
.news_articles_item2 {
    width: 30.9%;
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    min-height: 360px;
    margin-bottom: 30px;
}
.news_articles_item_img2 {
    width: 100%;
    height: 200px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    border: none;
}
.news_articles_item_img2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s ease-in-out;
}

.news_articles_item_info_box2 {
    width: 100%;
    padding: 19px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news_articles_item_title2 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.65px;
    margin-bottom: 23px;
    height: 90px;
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    border: none;
    text-align: left;
}

.news_articles_item_date_likes_info_wrapper2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.news_articles_item_date_info2 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}
.news_articles_item_like_icon_info_wrapper2 {
    display: flex;
    align-items: center;
}
.news_articles_item_like_icon_info2 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-left: 8px;
}


/*popular_news*/

.popular_news {
    width: 100%;
    margin-bottom: 57px;
    background: #2F333A;
    padding: 57px 0;
}

.popular_news_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.popular_news_title {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 35px;
}

.popular_news_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.popular_news_item {
    width: 48%;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    justify-content: space-between;
    padding: 23px;
    min-height: 227px;
}
.popular_news_item_img {
    width: 180px;
    height: 180px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    border: none;
}
.popular_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (min-width: 520px) and (max-width: 3000px) {
    .popular_news_item_img {
        margin-right: 28px;
    }
}

.popular_news_item:first-child {
    margin-bottom: 34px;
}
.popular_news_item:nth-child(2) {
    margin-bottom: 34px;
}

.popular_news_item_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.32px;
    margin-bottom: 23px;
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    border: none;
    text-align: left;
}

.popular_news_item_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 23px;
    height: 105px;
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}
.popular_news_item_user_info_likes_info_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.popular_news_item_user_info {
    display: flex;
    align-items: center;
}
.popular_news_item_user_img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 8px;
}
.popular_news_item_user_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.popular_news_item_like_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
}

.popular_news_item_like_icon_info_wrapper {
    display: flex;
    align-items: center;
}

.popular_news_item_user_name {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}

.popular_news_item_info_item {
    flex: 1;
}


/*banner*/
.banner {
    width: 100%;
    margin-bottom: 62px;
}
.banner_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.banner_img {
    width: 100%;
    height: 373px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}
.banner_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.banner_title {
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 61px;
    font-style: normal;
    font-weight: 700;
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, 37%);
}

/*news_articles3*/
.news_articles3 {
    width: 100%;
    padding-bottom: 57px;
}

.news_articles_title3 {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
}

/*favorites*/

.favorites {
    width: 100%;
    padding-bottom: 57px;
}
.favorites_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.favorites_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 35px;
}

.favorites_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.favorites_item {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 8px;
    width: 30.9%;
    position: relative;
    background: none;
    border: none;
}
.favorites_item_img {
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    height: 300px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    text-align: left;
}

.favorites_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s ease-in-out;
}

.favorites_item_info_box {
    position: absolute;
    z-index: 999;
    bottom: 19px;
    left: 30px;
    right: 30px;
}

.favorites_item_info {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 20.9px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 23px;
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    background: none;
    border: none;
    text-align: left;
}

.favorites_item_date_info_like_icon_info_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.favorites_item_date_info {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}

.favorites_item_like_info {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
}

.favorites_item_like_info_icon_wrapper {
    display: flex;
    align-items: flex-start;
}


/*send_news*/

.send_news {
    width: 100%;
    background: #2F333A;
    padding: 60px 0;
    /*padding-bottom: 57px;*/
}
.send_news_wrapper {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.send_news_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.send_news_img_items_wrapper {
    width: 100%;
    max-width: 540px;
}
.send_news_img_item {
    width: 100%;
    height: 363px;
    border-radius: 6px;
    overflow: hidden;
}
.send_news_img_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.send_news_img_item:first-child {
    margin-bottom: 20px;
}
.send_news_title {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 38px;
}

.send_news_form {
    width: 100%;
    max-width: 540px;
}

.send_news_form_input_wrapper {
    width: 100%;
    margin-bottom: 22px;
}
.send_news_form_input_field {
    border-radius: 6px;
    border: 1.5px solid #FFF;
    background: #FFF;
    width: 100%;
    padding: 20px;
    cursor: pointer;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.send_news_form_input_field::placeholder {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.send_news_form_input_field_dark {
    background-color: #2A2C38;
    border: 1px solid #2A2C38;
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    border-radius: 6px;
    width: 100%;
    padding: 20px;
    cursor: pointer;
    outline: none;
}

.send_news_form_input_field_dark::placeholder {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.send_news_form_textarea {
    border-radius: 6px;
    border: 1.5px solid #FFF;
    background: #FFF;
    width: 100%;
    padding: 20px;
    cursor: pointer;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    min-height: 280px;
    resize: none;
}
.send_news_form_textarea::placeholder {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.send_news_form_textarea_dark {
    background-color: #2A2C38;
    border: 1px solid #2A2C38;
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    border-radius: 6px;
    width: 100%;
    padding: 20px;
    cursor: pointer;
    outline: none;
    min-height: 280px;
    resize: none;
}

.send_news_form_textarea_dark::placeholder {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.send_news_info {
    color: #8C8C8C;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;

}
.send_news_privacy_policy_link {
    color: #FFFFFF;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    display: inline-block;
    margin-left: 2px;
}

.send_news_form_btn {
    border-radius: 6px;
    opacity: 0.9;
    background: #E71E24;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 30.4px; /* 160% */
    width: 100%;
    border: none;
    height: 68px;
}

.send_news_form_attach_a_file_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.send_news_form_attach_a_file_title {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-left: 15px;
}

.send_news_form_textarea_wrapper {
    width: 100%;
    margin-bottom: 12px;
}


/*mobile*/



@media (min-width: 426px) and (max-width: 1080px) {
    .main_news_item_info_box {
        left: 35px!important;
        right: 35px!important;
    }
    .main_news_item_title {
        font-size: 22px!important;
    }
    .main_news_item_img {
        height: 310px!important;
    }
    .main_news_item_img_child {
        height: 310px!important;
    }
}
@media (min-width: 485px) and (max-width: 950px) {
    .main_news_wrapper {
        flex-wrap: wrap;
    }
    .main_news_item {
        width: 50%;
    }
}
@media  (max-width: 485px) {
    .main_news_wrapper {
        flex-wrap: wrap;
    }
    .main_news_item {
        width: 100%;
    }
}


@media (min-width: 426px) and (max-width: 1120px) {
    .send_news_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .favorites_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .banner_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .popular_news_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .news_articles_wrapper2 {
        max-width: unset!important;
        width: 95% !important;
    }
    .new_ot_the_site_news_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .news_articles_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media (min-width: 426px) and (max-width: 768px) {
    .send_news_img_item {
        height: 280px!important;
    }
    .send_news_title {
        font-size: 20px!important;
    }
    .new_on_the_site_news_second_item_title {
        font-size: 20px!important;
    }
    .news_articles_title {
        font-size: 20px!important;
    }
    .news_articles_title2 {
        font-size: 20px!important;
    }
    .popular_news_title {
        font-size: 20px!important;
    }
    .favorites_title {
        font-size: 20px!important;
    }

}


@media (min-width: 769px) and (max-width: 1120px) {
    .new_ot_the_site_news_first_item {
        width: 30% !important;
        max-width: unset!important;
    }
    .new_on_the_site_news_second_item {
        width: 67% !important;
        max-width: unset!important;
    }
}
@media (min-width: 426px) and (max-width: 900px) {
    .new_ot_the_site_news_first_item {
        padding: 23px 16px!important;
    }
    .new_ot_the_site_news_item_title {
        font-size: 16px!important;
    }
    .new_on_the_site_news_item2_info_box_title {
        font-size: 17px!important;
    }
}

@media (max-width: 769px) {
    .favorites_items_wrapper {
        flex-wrap: wrap;
    }
    .favorites_item {
        width: 100%;
    }
    .favorites_item:not(:last-child) {
        margin-bottom: 22px;
    }
    .new_ot_the_site_news_wrapper {
        flex-direction: column;
    }
    .new_ot_the_site_news_first_item {
        width: 100% !important;
        max-width: unset!important;
        margin-bottom: 50px;
    }
    .new_on_the_site_news_second_item {
        width: 100% !important;
        max-width: unset!important;
    }

}

@media (max-width: 500px) {
    .new_on_the_site_news_item2 {
        width: 100% !important;
        margin-bottom: 24px!important;
        min-height: unset!important;
    }
    .new_on_the_site_news_item2_info_box_title {
        height: unset!important;
    }
}


@media (min-width: 426px) and (max-width: 975px) {
    .news_articles_item_info_box {
        padding: 19px!important;
    }
    .news_articles_item_info_box2 {
        padding: 15px!important;
    }
    .news_articles_item_title {
        font-size: 17px!important;
    }
    .news_articles_item_title2 {
        font-size: 17px!important;
    }
}
@media (max-width: 426px) {
    /*.send_news {*/
    /*    padding-bottom: 45px!important;*/
    /*}*/
    .send_news_img_item {
        height: 200px!important;
    }
    .send_news_form_btn {
        font-size: 15px!important;
    }
    .send_news_info {
        font-size: 12px!important;
    }
    .send_news_privacy_policy_link {
        font-size: 12px!important;
    }
    .send_news_form_input_field {
        font-size: 15px!important;
    }
    .send_news_form_input_field::placeholder {
        font-size: 15px!important;
    }
    .send_news_form_input_field_dark {
        font-size: 15px!important;
    }
    .send_news_form_input_field_dark::placeholder {
        font-size: 15px!important;
    }
    .send_news_title {
        font-size: 16.5px!important;
        margin-bottom: 30px!important;
    }
    .send_news_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .favorites {
        margin-bottom: 50px!important;
    }
    .favorites_title {
        font-size: 16.5px!important;
        margin-bottom: 23px!important;
    }
    .favorites_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .banner_img {
        height: 95px!important;
    }
    .banner_title {
        display: none!important;
    }
    .banner {
        margin-bottom: 59px!important;
    }
    .popular_news {
        margin-bottom: 50px!important;
    }
    .popular_news_title {
        font-size: 16.5px!important;
        margin-bottom: 24px!important;
    }
    .popular_news_item {
        padding: 19px!important;
    }
    .popular_news_item_title {
        margin-bottom: 19px!important;
        font-size: 12px!important;
        line-height: unset!important;
    }
    .popular_news_item_info {
        font-size: 15px!important;
        margin-bottom: 15px!important;
    }
    .popular_news_item_user_name {
        font-size: 12px!important;
    }
    .popular_news_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .banner_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .news_articles {
        padding: 46px 0!important;
    }
    .main_news_item_info_box {
        left: 30px!important;
        right: 30px!important;
    }
    .main_news_item_title {
        font-size: 20px!important;
        margin-bottom: 20px!important;
    }
    .main_news_item_date_info {
        font-size: 13px!important;
    }
    .main_news_item_img {
        height: 260px!important;
    }
    .main_news_item_img_child {
        height: 260px!important;
    }
    .main_news_item_img_child {
        height: 260px!important;
    }
    .main_news_item_like_icon svg {
        width: 17px!important;
        height: 17px!important;
    }
    .main_news_item_like_icon {
        width: 17px!important;
        height: 17px!important;
    }
    .new_on_the_site_news_second_item_title_btn_wrapper {
        margin-bottom: 25px!important;
    }
    .new_ot_the_site_news_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .news_articles_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .news_articles_wrapper2 {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
    .new_ot_the_site_news_first_item {
        padding: 20px!important;
    }
    .new_ot_the_site_news_item_title {
        font-size: 15px!important;
        margin-bottom: 20px!important;
    }
    .new_on_the_site_news_item2_info_box_title {
        font-size: 15px!important;
        margin-bottom: 20px!important;
    }
    .new_ot_the_site_news_first_item_title {
        font-size: 15px!important;
        margin-bottom: 24px!important;
    }
    .new_on_the_site_news_item2_date_info {
        font-size: 13px!important;
    }
    .new_on_the_site_news_item2_info_box {
        padding: 15px!important;
    }
    .new_ot_the_site_news_item {
        padding: 15px!important;
    }
    .new_on_the_site_news_second_item_title {
        font-size: 16.5px!important;
    }
    .news_articles_title_btn_wrapper {
        margin-bottom: 20px!important;
    }
    .news_articles_title_btn_wrapper2 {
        margin-bottom: 20px!important;
    }
    .news_articles_title {
        font-size: 16.5px!important;
    }
    .news_articles_title2 {
        font-size: 16.5px!important;
    }
    .news_articles_item_info_box {
        padding: 15px!important;
    }
    .news_articles_item_info_box2 {
        padding: 15px!important;
    }
    .news_articles_item_title {
        font-size: 15px!important;
        margin-bottom: 14px!important;
        height: unset!important;
    }
    .news_articles_item_date_info {
        font-size: 13px!important;
    }
    .news_articles_item_img {
        height: 175px!important;
    }

    .news_articles_item_title2 {
        font-size: 15px!important;
        margin-bottom: 14px!important;
        height: unset!important;
    }
    .news_articles_item_date_info2 {
        font-size: 13px!important;
    }
    .news_articles_item_img2 {
        height: 175px!important;
    }

}


@media (min-width: 521px) and (max-width: 768px) {
    .news_articles_item2 {
        width: 48% !important;
    }
    .news_articles_items_wrapper {
        flex-wrap: wrap;
    }
    .news_articles_item {
        width: 48%;
    }
    .news_articles_item:not(:last-child) {
        margin-bottom: 25px;
    }
}
@media  (max-width: 521px) {
    .news_articles_item2 {
        width: 100%!important;
        min-height: unset!important;
    }

    .news_articles_items_wrapper {
        flex-wrap: wrap;
    }
    .news_articles_item {
        width: 100%;
        min-height: unset!important;
    }
    .news_articles_item:not(:last-child) {
        margin-bottom: 25px;
    }

  .news_articles_item2:not(:last-of-type) {
        margin-bottom: 25px!important;
  }

    .news_articles_item2:last-child {
        margin-bottom: unset!important;
    }
}



@media (min-width: 989px) and (max-width: 1040px) {
    .popular_news_item {
        padding: 18px!important;
    }
    .popular_news_item_info {
        font-size: 17px!important;
    }
    .popular_news_item_user_name {
        font-size: 13px!important;
    }
}

@media (min-width: 426px) and (max-width: 989px) {
    .popular_news_item {
        width: 100% !important;
        margin-bottom: 34px!important;
        min-height: unset!important;
    }
}
@media  (max-width: 426px) {
    .popular_news_item {
        width: 100% !important;
        min-height: unset!important;
    }
    .popular_news_item:not(:last-child) {
        margin-bottom: 24px!important;
    }
    .popular_news {
        padding: 45px 0!important;
    }

}


@media (max-width: 520px) {

    .popular_news_item {
        flex-direction: column;
    }
    .popular_news_item_img {
        margin-bottom: 15px!important;
    }
    .popular_news_item_title {
        text-align: center;
    }
    .popular_news_item_info {
        text-align: center;
        height: unset!important;
    }
    .popular_news_item_user_info_likes_info_wrapper {
        padding-top: 20px;
        border-top: 1px solid #E3E4EA;
    }
}

@media (min-width: 521px) and (max-width: 768px) {
    .banner_img {
        height: 270px!important;
    }
    .banner_title {
        font-size: 48px!important;
    }
}
@media (min-width: 426px) and (max-width: 521px) {
    .banner_img {
        height: 180px!important;
    }
    .banner_title {
        font-size: 45px!important;
    }
}


@media (min-width: 769px) and (max-width: 900px) {
    .favorites_item_info {
        font-size: 18px!important;
    }
    .favorites_item_img {
        height: 270px!important;
    }
    .favorites_item_img_child {
        height: 270px!important;
    }
}
@media (min-width: 426px) and  (max-width: 769px) {
    .favorites_item_info {
        font-size: 18px!important;
    }
    .favorites_item_img {
        height: 300px!important;
    }
    .favorites_item_img_child {
        height: 300px!important;
    }
}
@media  (max-width: 426px) {
    .send_news_form_textarea {
        font-size: 15px!important;
    }
    .send_news_form_textarea::placeholder {
        font-size: 15px!important;
    }
    .send_news_form_textarea_dark {
        font-size: 15px!important;
    }
    .send_news_form_textarea_dark::placeholder {
        font-size: 15px!important;
    }
    .favorites_item_info {
        font-size: 16.5px!important;
        margin-bottom: 39px!important;
    }
    .favorites_item_img {
        height: 240px!important;
    }
    .favorites_item_img_child {
        height: 240px!important;
    }
    .favorites_item_date_info {
        font-size: 13px!important;
    }
    .favorites_item_info_box {
        left: 24px!important;
        right: 24px!important;
    }
}

@media (min-width: 768px) and (max-width: 1120px) {
    .send_news_form {
        width: 48% !important;
        max-width: unset!important;
    }
    .send_news_img_items_wrapper {
        width: 48% !important;
        max-width: unset!important;
    }
}
@media  (max-width: 768px) {
    .send_news_items_wrapper {
        flex-direction: column;
    }
    .send_news_form {
        width: 100% !important;
        max-width: unset!important;
        margin-bottom: 31px;
    }
    .send_news_img_items_wrapper {
        width: 100% !important;
        max-width: unset!important;
    }
}


.main_news_item_like_icon {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.new_on_the_site_news_item_like_icon {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.news_articles_item_like_icon {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}
.news_articles_item_like_icon2 {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}
.popular_news_item_like_icon {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

@media (min-width: 1120px) and (max-width: 3000px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 40px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 40px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 40px!important;
    }
}
@media (min-width: 1096px) and (max-width: 1120px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 38px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 38px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 38px!important;
    }
}
@media (min-width: 1067px) and  (max-width: 1096px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 37px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 37px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 37px!important;
    }
}
@media  (min-width: 1038px) and (max-width: 1067px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 36px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 36px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 36px!important;
    }
}
@media (min-width: 1009px) and (max-width: 1038px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 35px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 35px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 35px!important;
    }
}
@media (min-width: 981px) and  (max-width: 1009px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 34px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 34px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 34px!important;
    }
}
@media  (min-width: 951px) and  (max-width: 981px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 33px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 33px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 33px!important;
    }
}
@media   (min-width: 923px) and (max-width: 951px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 32px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 32px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 32px!important;
    }
}
@media   (min-width: 894px) and (max-width: 923px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 31px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 31px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 31px!important;
    }
}
@media  (min-width: 865px) and (max-width: 894px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 30px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 30px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 30px!important;
    }
}
@media  (min-width: 836px) and  (max-width: 865px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 29px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 29px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 29px!important;
    }
}
@media  (min-width: 808px) and  (max-width: 836px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 28px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 28px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 28px!important;
    }
}
@media  (min-width: 778px) and (max-width: 808px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 27px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 27px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 27px!important;
    }
}
@media  (min-width: 769px) and  (max-width: 778px) {
    .news_articles_item:not(:nth-child(3n)) {
        margin-right: 26px!important;
    }
    .news_articles_item2:not(:nth-child(3n)) {
        margin-right: 26px!important;
    }
    .favorites_item:not(:nth-child(3n)) {
        margin-right: 26px!important;
    }
}
@media    (max-width: 769px) {
    .news_articles_items_wrapper {
        justify-content: space-between!important;
    }
    .news_articles_items_wrapper2 {
        justify-content: space-between!important;
    }
    .favorites_items_wrapper {
        justify-content: space-between!important;
    }
}


.main_news_item_title:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.main_news_item_title:hover:after {
    visibility: visible;
    transform: scaleX(1);
}

.new_on_the_site_news_item2:hover .new_on_the_site_news_item2_info_box_title{
    text-decoration: underline;
}
.new_on_the_site_news_item2:hover .new_on_the_site_news_item_img img {
    transform: scale(1.15);
}

.news_articles_item:hover .news_articles_item_title {
    text-decoration: underline;
}

.news_articles_item:hover .news_articles_item_img img {
    transform: scale(1.15);
}

.news_articles_item2:hover .news_articles_item_title2 {
    text-decoration: underline;
}

.news_articles_item2:hover .news_articles_item_img2 img {
    transform: scale(1.15);
}



.favorites_item_info:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.favorites_item_info:hover:after {
    visibility: visible;
    transform: scaleX(1);
}

.favorites_item_info {
    position: relative;
}

.favorites_item_img_child {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}
