@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.search_page {
    width: 100%;
    /*margin-bottom: 57px;*/
    border-top: 0.8px solid #D4E4F2;
    padding-top: 44px;
}
.search_page_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.search_link_titles_info_wrapper {
    width: 100%;
    margin-bottom: 35px;

}
.search_link_titles_info_wrapper_child1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 23px;
}
.search_link_titles_info_wrapper_child2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    padding-bottom: 17px;
    border-bottom: 0.8px solid #D4E4F2;
}
.search_title_main {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 32.3px;
    font-style: normal;
    font-weight: 700;
}
.search_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
}
.search_link {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    /*margin-right: 16px;*/
    outline: none;
    cursor: pointer;
    display: flex;
}

.search_title {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
}
.search_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    /*padding-bottom: 40px;*/
}
.search_item {
    width: 32%;
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    min-height: 360px;
    margin-bottom: 30px;
}
.search_item_img {
    width: 100%;
    height: 200px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
}
.search_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.search_item_info_box {
    width: 100%;
    padding: 19px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.search_item_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.65px;
    margin-bottom: 23px;
    height: 90px;
}

.search_item_date_likes_info_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search_item_date_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}
.search_item_like_icon_info_wrapper {
    display: flex;
    align-items: center;
}
.search_item_like_icon_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-left: 8px;
}

.search_info2 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
}

/*media*/

@media (min-width: 426px) and (max-width: 1120px) {
    .search_page_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media (min-width: 426px) and (max-width: 975px) {
    .search_item_info_box {
        padding: 15px!important;
    }
    .search_item_title {
        font-size: 17px!important;
    }
}
@media (min-width: 521px) and (max-width: 768px) {
    .search_item {
        max-width: unset!important;
        width: 48% !important;
    }
}
@media  (max-width: 521px) {
    .search_link_titles_info_wrapper_child2 {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: flex-start!important;
        padding-bottom: 14px!important;
        margin-bottom: 30px!important;
    }
    .search_item {
        max-width: unset!important;
        width: 100% !important;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .search_title_main {
        font-size: 28px!important;
    }
    .search_info {
        font-size: 16px!important;
    }
}


@media  (max-width: 426px) {
    .search_info {
        font-size: 13px!important;
    }
    .search_title_main {
        font-size: 21px!important;
    }
    .search_link {
        font-size: 12px!important;
        margin-right: 13px!important;
    }
    .search_title {
        font-size: 12px!important;
    }
    .search_item_date_info {
        font-size: 13px!important;
    }
    .search_item_title {
        font-size: 15px!important;
        margin-bottom: 14px!important;
        height: unset!important;
    }
    .search_page_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .search_item_img {
        height: 175px!important;
    }

    .search_item_info_box {
        padding: 15px!important;
    }
}



.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}


.not_found_text {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}
