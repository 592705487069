@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.tribune_page {
    width: 100%;
    /*margin-bottom: 57px;*/
    border-top: 0.8px solid #D4E4F2;
    padding-top: 44px;
}
.tribune_page_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.tribune_link_titles_info_wrapper {
    width: 100%;
    margin-bottom: 35px;

}
.tribune_link_titles_info_wrapper_child1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 23px;
}
.tribune_link_titles_info_wrapper_child2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    padding-bottom: 17px;
    border-bottom: 0.8px solid #D4E4F2;
}
.tribune_title_main {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 32.3px;
    font-style: normal;
    font-weight: 700;
}
.tribune_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
}
.tribune_link {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    /*margin-right: 16px;*/
    outline: none;
    cursor: pointer;
    display: flex;
}

.tribune_title {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
}
.tribune_title2 {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}
.tribune_items_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}
.tribune_item {
    margin-bottom: 38px;
    width: 22.4%;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 1px 5px 25px 0px rgba(41, 64, 211, 0.10);
    height: 346px;
    position: relative;
    transition: .5s;
    cursor: pointer;
    top: 0;
    display: flex;
    flex-direction: column;
    outline: none;
    border: none;
}


.tribune_item:hover {
    top: -20px;
}

.tribune_item:hover .tribune_item_title {
    color: #E71E24!important;
}
.tribune_item:hover .tribune_item_author_like_icon_num_wrapper {
    background: #E71E24!important;
}

.tribune_item_img_box {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.tribune_item_img1 {
    width: 100%;
    height: 150px;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.tribune_item_img1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tribune_item_author_like_icon_num_wrapper {
    position: absolute;
    z-index: 9;
    right: 20px;
    top: 20px;
    border-radius: 8px;
    background: #2F333A;
    width: 57px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.tribune_item_author_like_icon_num {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.tribune_item_author_like_icon {
    height: 19px;
    margin-right: 5px;

}

.tribune_author_img {
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
}

.tribune_author_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.tribune_item_info_box {
    width: 100%;
    padding: 15px 20px 38px 20px;
}

.tribune_item_title {
    color: #2F333A;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    margin: 0 auto;
    margin-bottom: 15px;
    width: 121px;
}
.tribune_item_info {
    color: #2F333A;
    text-align: center;
    font-family: 'Rawline', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
}

.tribune_load_more_btn {
    border-radius: 6px;
    background: #E71E24;
    width: 288px;
    height: 68px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
}
.tribune_load_more_btn:hover {
    background: #D00000;
}


.tribune_load_more_btn span {
    margin-left: 15px;
}


/*media*/
@media (min-width: 1120px) and (max-width: 3000px) {
    .tribune_item:not(:nth-child(4n)) {
        margin-right: 38px; /* Adjust this value based on your design */
    }

}
@media (min-width: 426px) and (max-width: 1120px) {
    .tribune_page_wrapper {
        width: 95% !important;
        max-width: unset!important;
    }
}
@media (min-width: 1093px) and (max-width: 1120px) {
    .tribune_item:not(:nth-child(4n)) {
        margin-right: 36px!important;
    }

}
@media (min-width: 1063px) and (max-width: 1093px) {
    .tribune_item:not(:nth-child(4n)) {
        margin-right: 35px!important;
    }

}
@media (min-width: 1050px) and (max-width: 1063px) {
    .tribune_item:not(:nth-child(4n)) {
        margin-right: 34px!important;
    }

}
@media (min-width: 426px) and (max-width: 768px) {
    .tribune_title_main {
        font-size: 28px!important;
    }
    .tribune_item_title {
        font-size: 17px!important;
    }
    .tribune_item_info {
        font-size: 15px!important;
    }
}
@media  (max-width: 426px) {
    .tribune_item_author_like_icon {
        width: 18px!important;
        margin-right: 3px!important;
    }
    .tribune_item_author_like_icon svg {
        width: 18px!important;
        height: 18px!important;
    }
    .tribune_item_author_like_icon_num_wrapper {
        width: 49px!important;
        height: 33px!important;
    }
    .tribune_item_author_like_icon_num {
        font-size: 13px!important;
    }
    .tribune_item_info {
        font-size: 13px!important;
    }
    .tribune_item_title {
        font-size: 15px!important;
        margin-bottom: 9px!important;
    }
    .tribune_load_more_btn {
        font-size: 16px!important;
    }
    .tribune_page {
        padding-top: 34px!important;
        /*margin-bottom: 45px!important;*/
    }
    .tribune_page_wrapper {
        width: 100%!important;
        max-width: unset!important;
        padding-right: 30px;
        padding-left: 30px;
    }
    .tribune_link {
        font-size: 12px!important;
        margin-right: 13px!important;
    }
    .tribune_title {
        font-size: 12px!important;
    }
    .tribune_link_titles_info_wrapper_child2 {
        padding-bottom: 20px!important;
        margin-bottom: 30px!important;
    }
    .tribune_title_main {
        font-size: 21px!important;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .tribune_item {
        width: 32% !important;
        /*margin-right: 6px!important;*/
        /*margin-left: 6px!important;*/
    }
    .tribune_item:not(:nth-child(3n)) {
        margin-right: 18px!important;
    }
    /*.tribune_items_wrapper {*/
    /*    justify-content: center!important;*/
    /*}*/
}
@media (min-width: 840px) and (max-width: 950px) {
    .tribune_item {
        width: 32% !important;
        /*margin-right: 5px!important;*/
        /*margin-left: 5px!important;*/
    }
    .tribune_item:not(:nth-child(3n)) {
        margin-right: 16px!important;
    }
    /*.tribune_items_wrapper {*/
    /*    justify-content: center!important;*/
    /*}*/
}
@media (min-width: 788px) and (max-width: 840px) {
    .tribune_item {
        width: 32% !important;
        /*margin-right: 5px!important;*/
        /*margin-left: 5px!important;*/
    }
    .tribune_item:not(:nth-child(3n)) {
        margin-right: 14px!important;
    }
    /*.tribune_items_wrapper {*/
    /*    justify-content: center!important;*/
    /*}*/
}
@media (min-width: 521px) and  (max-width: 788px) {
    .tribune_item {
        width: 48% !important;
    }
    .tribune_items_wrapper {
        justify-content: space-between!important;
    }
}
@media (min-width: 521px) and  (max-width: 788px) {
    .tribune_item {
        width: 48% !important;
        margin-bottom: 30px!important;
    }
    .tribune_items_wrapper {
        justify-content: space-between!important;
    }

}
@media  (max-width: 521px) {
    .tribune_items_wrapper {
        justify-content: space-between!important;
    }
    .tribune_item_info_box {
        width: 100%;
        padding: 5px 15px 30px 15px!important;
    }
    .tribune_item {
        width: 100%!important;
        height: 300px!important;
    }

    .tribune_item:not(:last-child) {
        margin-bottom: 24px!important;
    }
}


.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}

.all_posts_popup {
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background:  rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
    padding-top: 50px;
}
.all_posts_popup_wrapper {
    width: 100%;
    max-width: 1523px;
    margin: 0 auto;
    background: #2F333A;
    padding-top: 60px;
    padding-bottom: 57px;
    position: relative;
}

.all_posts_popup_items_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0 auto;

}
.show_send_reminder_popup_title_close_btn {
    position: absolute;
    right: 20px;
    top: 20px;
}
.show_send_reminder_popup_title_close_btn2 {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.publication_feeds_item2 {
    width: 30.9%;
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    min-height: 360px;
    margin-bottom: 30px;
}

@media (min-width: 1216px) and (max-width: 1920px) {
    .publication_feeds_item2:not(:nth-child(3n)) {
        margin-right: 40px!important;
    }
}
@media (min-width: 1185px) and (max-width: 1280px) {
    .publication_feeds_item2:not(:nth-child(3n)) {
        margin-right: 35px!important;
    }
}
@media (min-width: 1125px) and  (max-width: 1185px) {
    .publication_feeds_item2:not(:nth-child(3n)) {
        margin-right: 30px!important;
    }
}
@media  (min-width: 1096px) and (max-width: 1125px) {
    .publication_feeds_item2:not(:nth-child(3n)) {
        margin-right: 25px!important;
    }
}
@media (min-width: 1009px) and (max-width: 1096px) {
    .publication_feeds_item2:not(:nth-child(3n)) {
        margin-right: 20px!important;
    }
}
@media (min-width: 981px) and  (max-width: 1009px) {
    .publication_feeds_item2:not(:nth-child(3n)) {
        margin-right: 15px!important;
    }
}
@media (min-width: 571px) and (max-width: 981px) {
    .publication_feeds_item2 {
       width: 48% !important;
    }
    .all_posts_popup_items_wrapper  {
        justify-content: space-between!important;
    }
}
@media (max-width: 571px) {
    .publication_feeds_item2 {
       width: 100% !important;
    }
    .all_posts_popup_items_wrapper  {
        justify-content: space-between!important;
    }
}

@media (max-width: 1550px) {
    .all_posts_popup_wrapper {
        width: 95% !important
    }
}
