@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.personal_area {
    width: 100%;
    border-top: 0.8px solid #D4E4F2;
    padding-top: 44px;
    /*margin-bottom: 57px;*/
}

.personal_area_wrapper {
    width: 100%;
    max-width: 1099px;
    margin: 0 auto;
}
.personal_area_link_title_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.personal_area_link {
    display: flex;
    cursor: pointer;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
    /*margin-right: 14px;*/
    text-decoration: none;
}

.personal_area_link_title {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 400;
}

.personal_area_main_title {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 30px;
    margin-bottom: 60px;
    border-bottom: 0.8px solid #D4E4F2;
}

.personal_area_items_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 877px;
    width: 100%;
}
.personal_area_item1 {
    max-width: 299px;
    width: 100%;
}
.personal_area_item_btn {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #D4E4F2;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    height: 58px;
    padding: 0 14px;
    cursor: pointer;
}

.personal_area_item_btn_title {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 600;
}

.personal_area_item_btn_icon1 {
    width: 31px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.personal_area_item_btn_icon1_last {
    width: 31px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.personal_area_item_btn_icon1:after {
    content: '';
    width: 1px;
    height: 38px;
    background: #2F333A;
    right: -30px;
    position: absolute;
}
.personal_area_item_btn_icon1_last:after {
    content: '';
    width: 1px;
    height: 38px;
    background: #2F333A;
    right: -30px;
    position: absolute;
}

.personal_area_item_btn:hover {
    background: #E71E24;
}
.personal_area_item_btn:hover .personal_area_item_btn_title {
    color: #FFFFFF;
}
.personal_area_item_btn:hover  .personal_area_item_btn_icon1:after {
    background: #FFFFFF;
}
.personal_area_item_btn:hover  .personal_area_item_btn_icon1 svg path {
    fill: #FFFFFF;
}
.personal_area_item_btn:hover  .personal_area_item_btn_icon1_last svg path {
    stroke: #FFFFFF;
}
.personal_area_item_btn:hover  .personal_area_item_btn_icon2 svg path{
    stroke: #FFFFFF;
}

/*active*/
.personal_area_item_btn_active {
    background: #E71E24;
    width: 100%;
    border-radius: 6px;
    /*border: 1px solid #D4E4F2;*/
    border: none;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    padding: 0 14px;
    cursor: pointer;
}
.personal_area_item_btn_active .personal_area_item_btn_title {
    color: #FFFFFF;
}
.personal_area_item_btn_active  .personal_area_item_btn_icon1:after {
    background: #FFFFFF;
}
.personal_area_item_btn_active  .personal_area_item_btn_icon1 svg path {
    fill: #FFFFFF;
}
.personal_area_item_btn_active  .personal_area_item_btn_icon1_last svg path {
    stroke: #FFFFFF;
}
.personal_area_item_btn_active  .personal_area_item_btn_icon1_last svg path {
    stroke: #FFFFFF;
}
.personal_area_item_btn_active  .personal_area_item_btn_icon2 svg path{
    stroke: #FFFFFF;
}

.personal_area_item2 {
    width: 100%;
    max-width: 518px;
}
.personal_area_item2_title {
    color: #2F333A;
    font-family: 'Evolventa',sans-serif;
    font-size: 34.2px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 35px;
}

.personal_area_item2_img_change_img_btn_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.personal_area_item2_img {
    width: 139px;
    height: 139px;
    margin-right: 40px;
    border-radius: 100%;
    overflow: hidden;
}

.personal_area_item2_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.personal_area_item2_img_change_img_btn {
    color: #E71E24;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.personal_area_form_input_field_title {
    color: #6F6F6F;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    /*bottom: 10px;*/
    transition: all 0.3s ease;
    left: 40px;
}


.personal_area_form_input_box_child_active .personal_area_form_input_field_title {
    transform: translateY(-40px);
    font-size: 14px!important;
    background: #FFFFFF;
    padding: 10px 30px;
}


.personal_area_form_input_box_child {
    border-radius: 6px;
    border: 1.5px solid rgba(82, 82, 82, 0.50);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 35px;
    padding: 25px 40px;
    background: #FFFFFF;
    position: relative;
}
.personal_area_form_input_box_child_active {
    border-radius: 6px;
    border: 1.5px solid #2F333A;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 35px;
    padding: 25px 40px;
    background: #FFFFFF;
    position: relative;
}


.personal_area_form_input_field {
    width: 100%;
    border: none;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    position: relative;
    background-color: unset;

}

.update_account_form {
    width: 100%;
}
.update_account_form_btn {
    border-radius: 6px;
    background: #E71E24;
    width: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    height: 78px;
}


.delete_account_form {
    width: 100%;
}
.delete_account_form_btn {
    border-radius: 6px;
    background: #E71E24;
    width: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    height: 78px;
}


.change_password_account_form {
    width: 100%;
}
.change_password_account_form_btn {
    border-radius: 6px;
    background: #E71E24;
    width: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    height: 78px;
}


/*media */

@media (min-width: 426px) and (max-width: 1120px) {
    .personal_area_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media (min-width: 426px) and  (max-width: 769px) {
    .personal_area_main_title {
        font-size: 28px!important;
        margin-bottom: 45px!important;
    }
    .personal_area_item2_title {
        font-size: 28px!important;
        margin-bottom: 25px!important;
    }
    .personal_area_item2_img {
        width: 91px!important;
        height: 91px!important;
        margin-right: 27px!important;
    }
    .personal_area_item2_img_change_img_btn_wrapper {
        margin-bottom: 25px!important;
    }
    .personal_area_item2_img_change_img_btn {
        font-size: 16px!important;
    }
    .personal_area_form_input_box_child {
        padding: 20px 30px!important;
        margin-bottom: 25px!important;
    }
    .personal_area_form_input_box_child_active {
        padding: 20px 30px!important;
        margin-bottom: 25px!important;
    }
    .personal_area_form_input_field_title {
        font-size: 17px!important;
        left: 30px!important;
    }
    .personal_area_form_input_field {
        font-size: 17px!important;
    }
    .update_account_form_btn {
        font-size: 17px!important;
        height: 55px!important;
    }
    .change_password_account_form_btn {
        font-size: 17px!important;
        height: 55px!important;
    }
    .delete_account_form_btn {
        font-size: 17px!important;
        height: 55px!important;
    }
}
@media  (max-width: 426px) {
    .delete_account_form_btn {
        font-size: 15px!important;
        height: 45px!important;
    }
    .change_password_account_form_btn {
        font-size: 15px!important;
        height: 45px!important;
    }
    .update_account_form_btn {
        font-size: 15px!important;
        height: 45px!important;
    }
    .personal_area_form_input_field {
        font-size: 14px!important;
    }
    .personal_area_form_input_field_title {
        font-size: 14px!important;
        left: 22px!important;
    }
    .personal_area_form_input_box_child {
        padding: 14px 22px!important;
        margin-bottom: 15px!important;
    }
    .personal_area_form_input_box_child_active {
        padding: 14px 22px!important;
        margin-bottom: 15px!important;
    }
    .personal_area_item2_img_change_img_btn {
        font-size: 14px!important;
    }
    .personal_area_item2_img_change_img_btn_wrapper {
        margin-bottom: 20px!important;
    }
    .personal_area_item2_img {
        width: 81px!important;
        height: 81px!important;
        margin-right: 24px!important;
    }
    .personal_area_item2_title {
        font-size: 20px!important;
        margin-bottom: 20px!important;
    }
    .personal_area_item_btn_icon2 svg {
        width: 12px!important;
        height: 17px!important;
    }
    .personal_area_item_btn_active {
        height: 56px!important;
    }
    .personal_area_item_btn {
        height: 56px!important;
    }
    .personal_area_main_title {
        font-size: 21px!important;
        margin-bottom: 30px!important;
    }
    .personal_area_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
    .personal_area {
        padding-top: 34px!important;
        /*margin-bottom: 45px!important;*/
    }
    .personal_area_link_title {
        font-size: 12px!important;
    }
    .personal_area_link {
        font-size: 12px!important;
    }
}

@media (min-width: 769px) and (max-width: 880px) {
    .personal_area_item1 {
        max-width: unset!important;
        width: 35%!important;
    }
    .personal_area_item2 {
        max-width: unset!important;
        width: 63% !important;
    }
    .personal_area_item_btn_icon1:after {
        right: -16px!important;
    }
}


@media (max-width: 769px) {
    .personal_area_items_wrapper {
        flex-direction: column;
    }
    .personal_area_item1 {
        max-width: unset!important;
        width: 100%!important;
        margin-bottom: 50px;
    }
    .personal_area_item2 {
        max-width: unset!important;
        width: 100%!important;
    }
    .personal_area_item_btn_icon1:after {
        right: -16px!important;
    }
}

.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}

.success_password_popup {
    /*display: none;*/
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background:  rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    padding-top: 100px;
}

.success_password_popup_wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 20px;
    overflow: scroll;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
}

.success_password_popup_info {
    text-align: center;
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 40px;
}

.success_password_popup_close_btn {
    position: absolute;
    z-index: 99;
    cursor: pointer;
    outline: none;
    right: 20px;
    top: 20px;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
