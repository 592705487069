@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.events_calendar_single_page {
    width: 100%;
    border-top: 0.8px solid #D4E4F2;
    padding-top: 44px;
    /*margin-bottom: 57px;*/
}
.events_calendar_single_page_wrapper {
    width: 100%;
}

.events_calendar_single_page_title_links_main_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 0.8px solid #D4E4F2;
}

.events_calendar_single_page_links_info_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
}
.events_calendar_single_page_link {
    display: flex;
    cursor: pointer;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    /*margin-right: 13px;*/
    text-decoration: none;
}
.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}

.events_calendar_single_page_link_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.events_calendar_single_page_title {
    color: #2F333A;
    font-family: 'Evolventa',sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    max-width: 753px;
    width: 100%;
}

.events_calendar_single_page_img_main_info_wrapper {
    width: 100%;
    position: relative;
}
.events_calendar_single_page_img {
    width: 100%;
    height: 360px;
    position: relative;
}

.events_calendar_single_page_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.events_calendar_single_page_img_info_box {
    position: absolute;
    z-index: 99;
    top: 0;
    width: 100%;
}
.events_calendar_single_page_img_info_box_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.events_calendar_single_page_img_info_box_child_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 58px;
}
.events_calendar_single_page_event_logo {
    width: 220px;
    height: 210px;
    /*background: #FFFFFF;*/
    /*padding: 29px 9px 0 9px;*/
    margin-right: 20px;
}


.events_calendar_single_page_event_logo img {
    /*width: 200px;*/
    /*height: 123px;*/
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.events_calendar_single_page_event_logo_dark {
    width: 220px;
    height: 210px;
    margin-right: 20px;
}


.events_calendar_single_page_event_logo_dark img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.events_calendar_single_page_img_info_box_child1 {
    max-width: 774px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.events_calendar_single_page_img_info_box_child1_title {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 14px;
    margin-top: 33px;
}

.events_calendar_single_page_img_info_box_child1_info1 {
    color: #FFF;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 25px;
}
.events_calendar_single_page_img_info_box_child1_info2 {
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    width: 76%;
}



.events_calendar_single_page_img_info_box_child2 {
    width: 231px;
    margin-top: 33px;
}

.events_calendar_single_page_img_info_box_child2_info {
    color: #FFF;
    font-family: 'Evolventa', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.add_to_calendar_event_btn {
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
}

.add_to_calendar_event_btn span {
    margin-right: 16px;
}

.send_a_reminder_event_single_btn {
    border-radius: 6px;
    background: #E71E24;
    width: 286px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: 'Rawline',sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
}

.send_a_reminder_event_single_btn:hover {
    background: #D00000;
}
.events_calendar_single_page_details_main_wrapper {
    width: 100%;
    background: rgba(212, 228, 242, 0.30);
    padding: 57px 0;
}

.events_calendar_single_page_details_items_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.events_calendar_single_page_details_item {
    width: 24%;
}

.events_calendar_single_page_details_item_title {
    color: rgba(47, 51, 58, 0.85);
    font-family: 'Evolventa',sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 20px;
}

.events_calendar_single_page_details_item_title2 {
    color: rgba(47, 51, 58, 0.85);
    font-family: 'Evolventa',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 32px;
}

.events_calendar_single_page_details_item_child_title {
    color: rgba(47, 51, 58, 0.75);
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}

.events_calendar_single_page_details_item_child_info {
    color: rgba(47, 51, 58, 0.60);
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.events_calendar_single_page_details_item_child:first-of-type {
    margin-bottom: 31px;
}

.events_calendar_single_page_details_item_info {
    color: rgba(47, 51, 58, 0.75);
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.events_calendar_single_page_details_item_info_icon_wrapper {
    display: flex;
    align-items: flex-start;
}

.events_calendar_single_page_details_item_info_icon {
    position: relative;
    top: 5px;
    margin-right: 6px;
}

.numbered_list_main_wrapper {
    width: 100%;
    padding-top: 57px;
    padding-bottom: 57px;
    border-bottom: 1px solid #D4E4F2;
}

.numbered_list_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.numbered_list_title {
    color: #2F333A;
    font-family: 'Evolventa',sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 33px;
}
.numbered_list_items_wrapper {
    width: 100%;
}
.numbered_list_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.numbered_list_item_step_box {
    width: 22px;
    height: 22px;
    margin-right: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #E71E24;
    overflow: hidden;
    position: relative;
}

.numbered_list_item_step {
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.75px;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -52%);
}

.numbered_list_item_step_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    flex: 1;
}

.numbered_list_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    max-width: 747px;
}

.russian_organizers_contacts_main_wrapper {
    width: 100%;
    border-bottom: 1px solid #D4E4F2;
    padding-top: 57px;
    padding-bottom: 57px;
}

.russian_organizers_contacts_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.russian_organizers_wrapper {
    width: 100%;
    margin-bottom: 60px;
}
.russian_organizers_contacts_title {
    color: rgba(47, 51, 58, 0.85);
    font-family: 'Evolventa',sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 35px;
}

.russian_organizers_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.russian_organizers_item {
    width: 49%;
}
.russian_organizers_item_title {
    color: rgba(47, 51, 58, 0.75);
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 9px;
}
.russian_organizers_item_info1 {
    color: rgba(47, 51, 58, 0.60);
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 6px;
}
.russian_organizers_item_address_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 6px;
}
.russian_organizers_item_phone_numbers {
    display: flex;
    margin-bottom: 6px;
}
.russian_organizers_item_phone_number {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
}

.russian_organizers_item_hour_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 6px;
}

.russian_organizers_item_email {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.russian_organizers_item_email span {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-right: 8px;
}
.russian_organizers_item_email_link {
    display: flex;
    cursor: pointer;
    outline: none;
    color: #E71E24;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.russian_organizers_item_website {
    color: #E71E24;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.russian_organizers_item_phone_numbers_info_wrapper {
    display: flex;
    margin-bottom: 6px;
    align-items: center;
}

.russian_organizers_item_phone_numbers_info_wrapper span {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 116.667% */
}

.russian_organizers_item_phone_number_link2 {
    width: 100%;
    display: block;
}
.russian_organizers_item_phone_numbers_box_main_wrapper {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
}
.russian_organizers_item_phone_number_link {
    display: flex;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    margin-right: 5px;
    margin-left: 5px;
}
.russian_organizers_item_phone_number_link2 {
    display: flex;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.russian_organizers_item_fax {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.russian_organizers_item_fax span {
    margin-right: 5px;
}

.russian_contacts_wrapper {
    width: 100%;
}

.russian_contacts_title {
    color: rgba(47, 51, 58, 0.85);
    font-family: 'Rawline',sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 35px;
}



.russian_contacts_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.russian_contacts_item {
    width: 50%;
}
.russian_contacts_item_name {
    margin-bottom: 12px;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
}

.russian_contacts_item_number_title_box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.russian_contacts_item_numbers_title {
    margin-right: 6px;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.russian_contacts_item_number_link {
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #2F333A;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    outline: none;
}

.russian_contacts_item_email_title_box {
    display: flex;
    align-items: center;
}

.russian_contacts_item_email_title {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-right: 6px;
}

.russian_contacts_item_email_link{
    color: #E71E24;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    outline: none;
}

.show_send_reminder_popup {
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background:  rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
    padding-top: 50px;
}
.show_send_reminder_popup_wrapper {
    width: 100%;
    max-width: 1523px;
    margin: 0 auto;
    background: #2F333A;
    padding-top: 60px;
    padding-bottom: 57px;
}

.show_send_reminder_popup_wrapper_child {
    width: 100%;
    max-width: 1099px;
    margin: 0 auto;
}

.show_send_reminder_popup_form_img {
    width: 100%;
    max-width: 540px;
    height: 489px;
    border-radius: 6px;
    overflow: hidden;
}

.show_send_reminder_popup_form_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.show_send_reminder_popup_title_close_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.8px solid #D4E4F2;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.show_send_reminder_popup_title1 {
    color: #FFF;
    font-family: 'Evolventa',sans-serif;
    font-size: 24.7px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 36px;
}
.show_send_reminder_popup_title2 {
    color: #FFF;
    font-family: 'Evolventa',sans-serif;
    font-size: 34.2px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    max-width: 753px;
}

.show_send_reminder_popup_title_close_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.show_send_reminder_popup_form_img_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.show_send_reminder_popup_form {
    width: 100%;
    max-width: 540px;
}

.show_send_reminder_popup_form_input_field {
    border-radius: 6px;
    border: 1.5px solid #FFF;
    background: #FFF;
    width: 100%;
    height: 68px;
    padding: 17px 20px 26px 20px;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.show_send_reminder_popup_form_input_field_dark {
    border-radius: 6px;
    border: 1.5px solid #2A2C38;
    background: #2A2C38;
    width: 100%;
    height: 68px;
    padding: 17px 20px 26px 20px;
    color: #FFFFFF;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.show_send_reminder_popup_form_input_field_dark::placeholder {
    color: #FFFFFF;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}
.show_send_reminder_popup_form_input_field::placeholder {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}
.show_send_reminder_popup_form_input_parent {
    width: 100%;
    margin-bottom: 22px;
}

.show_send_reminder_popup_info {
    color: #8C8C8C;
    font-family: 'Rawline',sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
}
.show_send_reminder_popup_info_link {
    display: inline-block;
    cursor: pointer;
    outline: none;
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    background: none;
    border: none;
}
.show_send_reminder_popup_form_btn {
    border-radius: 6px;
    opacity: 0.9;
    background: #E71E24;
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    outline: none;
}
.show_send_reminder_popup_form_btn:hover {
    background: #D00000;
}

.mobile_events_calendar_single_page_img {
    display: none;
    width: 100%;
    position: relative;
    height: 590px;
}
.mobile_events_calendar_single_page_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/*media */

@media (min-width: 426px) and (max-width: 1120px) {
    .events_calendar_single_page_title_links_main_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .events_calendar_single_page_img_info_box_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .events_calendar_single_page_details_items_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .numbered_list_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .russian_organizers_contacts_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media (max-width: 426px) {

    .show_send_reminder_popup_form_btn {
        font-size: 15px!important;
    }
    .show_send_reminder_popup_info {
        font-size: 12px!important;
    }
    .show_send_reminder_popup_info_link {
        font-size: 12px!important;
    }
    .show_send_reminder_popup_form_input_field {
        font-size: 15px!important;
        padding: 20px!important;
        height: 65px!important;
    }
    .show_send_reminder_popup_form_input_field::placeholder {
        font-size: 15px!important;
    }
    .show_send_reminder_popup_title2 {
        font-size: 20px!important;
    }
    .show_send_reminder_popup_title1 {
        font-size: 16px!important;
    }
    .show_send_reminder_popup_wrapper {
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
    .show_send_reminder_popup_form_img {
        height: 200px!important;
    }

    .events_calendar_single_page_title_links_main_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .events_calendar_single_page_img_info_box_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .events_calendar_single_page_details_items_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .numbered_list_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .russian_organizers_contacts_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 896px) and (max-width: 1060px) {
    .events_calendar_single_page_event_info_box {
        width: 67% !important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        width: 30% !important;
    }
    .events_calendar_single_page_event_logo {
        width: 180px!important;
        height: 190px!important;
    }
    .events_calendar_single_page_event_logo img {
        width: 160px!important;
        height: 100px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info1 {
        font-size: 16px!important;
        margin-bottom: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        font-size: 16px!important;
    }
    .add_to_calendar_event_btn {
        font-size: 16px!important;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        font-size: 30px!important;
        margin-bottom: 10px!important;
    }
    .add_to_calendar_event_btn span {
        margin-right: 8px!important;
        width: 18px!important;
    }
    .add_to_calendar_event_btn span svg {
        width: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child2_info {
        font-size: 18px!important;
    }
}
@media  (min-width: 838px) and (max-width: 896px) {
    .events_calendar_single_page_details_item {
        width: 32% !important;
        margin-bottom: 30px!important;
    }
    .events_calendar_single_page_details_items_wrapper {
        flex-wrap: wrap;
    }
    .events_calendar_single_page_event_info_box {
        width: 67% !important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        width: 30% !important;
    }
    .events_calendar_single_page_event_logo {
        width: 160px!important;
        height: 170px!important;
    }
    .events_calendar_single_page_event_logo img {
        width: 140px!important;
        height: 90px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info1 {
        font-size: 15px!important;
        margin-bottom: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        font-size: 15px!important;
    }
    .add_to_calendar_event_btn {
        font-size: 15px!important;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        font-size: 28px!important;
        margin-bottom: 10px!important;
    }
    .add_to_calendar_event_btn span {
        margin-right: 6px!important;
        width: 18px!important;
    }
    .add_to_calendar_event_btn span svg {
        width: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child2_info {
        font-size: 17px!important;
    }
}


@media (min-width: 769px) and (max-width: 838px) {
    .events_calendar_single_page_details_item {
        width: 32% !important;
        margin-bottom: 30px!important;
    }
    .events_calendar_single_page_details_items_wrapper {
        flex-wrap: wrap;
    }
    .events_calendar_single_page_event_info_box {
        width: 67% !important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        width: 100% !important;
    }
    .events_calendar_single_page_event_logo {
        width: 160px!important;
        height: 170px!important;
    }
    .events_calendar_single_page_event_logo img {
        width: 140px!important;
        height: 90px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info1 {
        font-size: 15px!important;
        margin-bottom: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        font-size: 15px!important;
    }
    .add_to_calendar_event_btn {
        font-size: 15px!important;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        font-size: 28px!important;
        margin-bottom: 10px!important;
    }
    .add_to_calendar_event_btn span {
        margin-right: 10px!important;
        width: 18px!important;
    }
    .add_to_calendar_event_btn span svg {
        width: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child2_info {
        font-size: 17px!important;
    }
    .events_calendar_single_page_img_info_box_child_wrapper {
        flex-direction: column;
    }
    .events_calendar_single_page_img {
        height: 430px!important;
    }
    .events_calendar_single_page_img_info_box_child_wrapper {
        margin-bottom: 48px!important;
    }
}

@media (min-width: 769px) and (max-width: 1060px) {

    .events_calendar_single_page_details_item_title {
        font-size: 20px!important;
    }
    .events_calendar_single_page_details_item_info {
        font-size: 16px!important;
    }
    .events_calendar_single_page_details_item_child_info {
        font-size: 16px!important;
    }
    .events_calendar_single_page_details_item_child_title {
        font-size: 16px!important;
    }
}

@media (min-width: 426px) and (max-width: 769px) {
    .show_send_reminder_popup_form_input_field {
        font-size: 18px!important;
    }
    .show_send_reminder_popup_form_input_field::placeholder {
        font-size: 18px!important;
    }
    .show_send_reminder_popup_title2 {
        font-size: 28px!important;
    }
    .show_send_reminder_popup_title1 {
        font-size: 20px!important;
    }
    .show_send_reminder_popup_form_img {
        height: 300px!important;
    }

    .send_a_reminder_event_single_btn {
        font-size: 17px!important;
    }

    .events_calendar_single_page_img_info_box_child1_title {
        margin-top: unset!important;
    }
    .mobile_events_calendar_single_page_img {
        display: block!important;
    }

    .events_calendar_single_page_img_info_box_child1 {
        max-width: unset!important;
        flex-direction: column;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        width: 100% !important;
    }
    .events_calendar_single_page_event_logo {
        margin-right: unset!important;
        margin-bottom: 30px;
    }
    .events_calendar_single_page_event_info_box {
        width: 100% !important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        width: 100% !important;
    }
    .events_calendar_single_page_event_logo {
        width: 160px!important;
        height: 170px!important;
    }
    .events_calendar_single_page_event_logo img {
        width: 140px!important;
        height: 90px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info1 {
        font-size: 15px!important;
        margin-bottom: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        font-size: 15px!important;
    }
    .add_to_calendar_event_btn {
        font-size: 15px!important;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        font-size: 28px!important;
        margin-bottom: 10px!important;
    }
    .add_to_calendar_event_btn span {
        margin-right: 10px!important;
        width: 18px!important;
    }
    .add_to_calendar_event_btn span svg {
        width: 18px!important;
    }
    .events_calendar_single_page_img_info_box_child2_info {
        font-size: 17px!important;
    }
    .events_calendar_single_page_img_info_box_child_wrapper {
        flex-direction: column;
    }

    .events_calendar_single_page_img_info_box_child_wrapper {
        margin-bottom: 40px!important;
    }
}

@media (min-width: 485px) and (max-width: 769px) {
    .events_calendar_single_page_details_item {
        width: 48% !important;
        margin-bottom: 30px!important;
    }
    .events_calendar_single_page_details_items_wrapper {
        flex-wrap: wrap;
    }
    .events_calendar_single_page_details_main_wrapper {
        padding-bottom: 20px!important;
    }
}
@media  (max-width: 485px) {
    .mobile_phone_word {
        display: none!important;
    }
    .events_calendar_single_page_details_item {
        width: 100% !important;
    }
    .events_calendar_single_page_details_item:not(:last-child) {
        margin-bottom: 30px!important;
    }
    .events_calendar_single_page_details_items_wrapper {
        flex-wrap: wrap;
    }
}

@media (min-width: 426px) and (max-width: 769px) {
    .events_calendar_single_page_details_item_info {
        font-size: 16px!important;
    }
    .russian_organizers_item_phone_number_link2 {
        font-size: 16px!important;
    }
    .russian_organizers_item_fax {
        font-size: 16px!important;
    }
    .russian_organizers_item_phone_numbers_info_wrapper span {
        font-size: 16px!important;
    }
    .russian_organizers_item_phone_number_link {
        font-size: 16px!important;
    }
    .russian_organizers_item_website {
        font-size: 16px!important;
    }
    .russian_organizers_item_email_link {
        font-size: 16px!important;
    }
    .russian_organizers_item_email span {
        font-size: 16px!important;
    }
    .russian_organizers_item_phone_number {
        font-size: 16px!important;
    }
    .russian_organizers_item_address_info {
        font-size: 16px!important;
    }
    .russian_organizers_item_info1 {
        font-size: 16px!important;
    }
    .russian_organizers_item_title {
        font-size: 16px!important;
    }
    .numbered_list_item_step_info {
        font-size: 16px!important;
    }
    .events_calendar_single_page_details_item_child:first-of-type {
        margin-bottom: 21px!important;
    }
    .events_calendar_single_page_details_item_child_info {
        font-size: 16px!important;
    }
    .events_calendar_single_page_details_item_child_title {
        font-size: 16px!important;
    }
    .events_calendar_single_page_title {
        font-size: 28px!important;
    }
    .events_calendar_single_page_link {
        font-size: 14px!important;
    }
    .events_calendar_single_page_link_info {
        font-size: 14px!important;
    }
    .events_calendar_single_page_title_links_main_wrapper {
        padding-bottom: 30px!important;
    }
}

@media  (min-width: 375px) and (max-width: 426px) {
    .events_calendar_single_page_details_item_info {
        font-size: 14px!important;
    }

    .russian_contacts_item_name {
        font-size: 16px!important;
        margin-bottom: 12px!important;
    }
    .russian_contacts_title {
        font-size: 18px!important;
        margin-bottom: 30px!important;
    }
    .russian_organizers_item_fax {
        font-size: 14px!important;
    }
    .russian_organizers_item_phone_number_link2 {
        font-size: 14px!important;
    }
    .russian_organizers_item_phone_numbers_info_wrapper span {
        font-size: 14px!important;
    }
    .russian_organizers_item_phone_number_link {
        font-size: 14px!important;
    }
    .russian_organizers_item_website {
        font-size: 14px!important;
    }
    .russian_organizers_item_email_link {
        font-size: 14px!important;
    }
    .russian_organizers_item_email span {
        font-size: 14px!important;
    }
    .russian_organizers_item_phone_number {
        font-size: 14px!important;
    }
    .russian_organizers_item_address_info {
        font-size: 14px!important;
        margin-bottom: 9px!important;
    }
    .russian_organizers_item_info1 {
        font-size: 14px!important;
        margin-bottom: 9px!important;
    }
    .russian_organizers_item_title {
        font-size: 14px!important;
    }
    .russian_organizers_contacts_title {
        font-size: 18px!important;
        margin-bottom: 30px!important;
    }
    .russian_organizers_contacts_main_wrapper {
        padding-bottom: 51px!important;
        padding-top: 50px!important;
    }
    .numbered_list_main_wrapper {
        padding-top: 50px!important;
    }
    .numbered_list_info {
        font-size: 15px!important;
    }
    .numbered_list_item_step_info {
        font-size: 14px!important;
    }
    .numbered_list_title {
        font-size: 18px!important;
        margin-bottom: 23px!important;
    }
    .events_calendar_single_page_details_main_wrapper {
        padding: 50px 0!important;
    }
    .events_calendar_single_page_details_item_child:first-of-type {
        margin-bottom: 11px!important;
    }
    .events_calendar_single_page_details_item_child_info {
        font-size: 14px!important;
    }
    .events_calendar_single_page_details_item_child_title {
        font-size: 14px!important;
    }
    .events_calendar_single_page_details_item_title2 {
        margin-bottom: 12px!important;
    }
    .mobile_events_calendar_single_page_img {
        height: 570px!important;
    }
    .send_a_reminder_event_single_btn {
        font-size: 15px!important;
        width: 100% !important;
        margin: 0 auto;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        margin-top: unset!important;
    }
    .mobile_events_calendar_single_page_img {
        display: block!important;
    }

    .events_calendar_single_page_img_info_box_child1 {
        max-width: unset!important;
        flex-direction: column;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        width: 100% !important;
    }
    .events_calendar_single_page_event_logo {
        margin-right: unset!important;
        margin-bottom: 30px;
    }
    .events_calendar_single_page_event_info_box {
        width: 100% !important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        width: 100% !important;
    }
    .events_calendar_single_page_event_logo {
        width: 144px!important;
        height: 137px!important;
    }
    .events_calendar_single_page_event_logo img {
        width: 131px!important;
        height: 80px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info1 {
        font-size: 14px!important;
        margin-bottom: 20px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        font-size: 14px!important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        margin-top: 20px!important;
    }
    .add_to_calendar_event_btn {
        font-size: 14px!important;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        font-size: 20px!important;
        margin-bottom: 5px!important;
    }
    .add_to_calendar_event_btn span {
        margin-right: 16px!important;
        width: 22px!important;
        height: 24px!important;
    }
    .add_to_calendar_event_btn span svg {
        width: 22px!important;
        height: 24px!important;
    }
    .events_calendar_single_page_img_info_box_child2_info {
        font-size: 16px!important;
    }
    .events_calendar_single_page_img_info_box_child_wrapper {
        flex-direction: column;
    }

    .events_calendar_single_page_img_info_box_child_wrapper {
        margin-bottom: 30px!important;
    }



    .events_calendar_single_page {
        padding-top: 34px!important;
    }
    .events_calendar_single_page_title_links_main_wrapper {
        padding-bottom: 15px!important;
    }
    .events_calendar_single_page_title {
        font-size: 21px!important;
    }
    .events_calendar_single_page_links_info_wrapper {
        margin-bottom: 20px!important;
    }
    .events_calendar_single_page_link {
        font-size: 12px!important;
    }
    .events_calendar_single_page_link_info {
        font-size: 12px!important;
    }
}
@media  (max-width: 375px) {
    .events_calendar_single_page_details_item_info {
        font-size: 14px!important;
    }
    .events_calendar_single_page_details_item_title {
        font-size: 20px!important;
        margin-bottom: 17px!important;
    }
    .russian_contacts_item_email_title {
        font-size: 16px!important;
    }
    .russian_contacts_item_numbers_title {
        font-size: 16px!important;
    }
    .russian_contacts_item_number_link {
        font-size: 16px!important;
    }
    .russian_contacts_item_email_link {
        font-size: 16px!important;
    }
    .russian_organizers_item_hour_info {
        font-size: 16px!important;
    }

    .russian_contacts_item_name {
        font-size: 14px!important;
        margin-bottom: 12px!important;
    }
    .russian_contacts_title {
        font-size: 18px!important;
        margin-bottom: 30px!important;
    }
    .russian_organizers_item_fax {
        font-size: 12px!important;
    }
    .russian_organizers_item_phone_number_link2 {
        font-size: 12px!important;
    }
    .russian_organizers_item_phone_numbers_info_wrapper span {
        font-size: 12px!important;
    }
    .russian_organizers_item_phone_number_link {
        font-size: 12px!important;
    }
    .russian_organizers_item_website {
        font-size: 12px!important;
    }
    .russian_organizers_item_email_link {
        font-size: 12px!important;
    }
    .russian_organizers_item_email span {
        font-size: 12px!important;
    }
    .russian_organizers_item_phone_number {
        font-size: 12px!important;
    }
    .russian_organizers_item_address_info {
        font-size: 12px!important;
        margin-bottom: 9px!important;
    }
    .russian_organizers_item_info1 {
        font-size: 12px!important;
        margin-bottom: 9px!important;
    }
    .russian_organizers_item_title {
        font-size: 12px!important;
    }
    .russian_organizers_contacts_title {
        font-size: 18px!important;
        margin-bottom: 30px!important;
    }
    .russian_organizers_contacts_main_wrapper {
        padding-bottom: 51px!important;
        padding-top: 50px!important;
    }
    .numbered_list_main_wrapper {
        padding-top: 50px!important;
    }
    .numbered_list_info {
        font-size: 15px!important;
    }
    .numbered_list_item_step_info {
        font-size: 14px!important;
    }
    .numbered_list_title {
        font-size: 18px!important;
        margin-bottom: 23px!important;
    }
    .events_calendar_single_page_details_main_wrapper {
        padding: 50px 0!important;
    }
    .events_calendar_single_page_details_item_child:first-of-type {
        margin-bottom: 11px!important;
    }
    .events_calendar_single_page_details_item_child_info {
        font-size: 14px!important;
    }
    .events_calendar_single_page_details_item_child_title {
        font-size: 14px!important;
    }
    .events_calendar_single_page_details_item_title2 {
        margin-bottom: 12px!important;
    }
    .mobile_events_calendar_single_page_img {
        height: 570px!important;
    }
    .send_a_reminder_event_single_btn {
        font-size: 15px!important;
        width: 100% !important;
        margin: 0 auto;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        margin-top: unset!important;
    }
    .mobile_events_calendar_single_page_img {
        display: block!important;
    }

    .events_calendar_single_page_img_info_box_child1 {
        max-width: unset!important;
        flex-direction: column;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        width: 100% !important;
    }
    .events_calendar_single_page_event_logo {
        margin-right: unset!important;
        margin-bottom: 30px;
    }
    .events_calendar_single_page_event_info_box {
        width: 100% !important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        width: 100% !important;
    }
    .events_calendar_single_page_event_logo {
        width: 144px!important;
        height: 137px!important;
    }
    .events_calendar_single_page_event_logo img {
        width: 131px!important;
        height: 80px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info1 {
        font-size: 14px!important;
        margin-bottom: 20px!important;
    }
    .events_calendar_single_page_img_info_box_child1_info2 {
        font-size: 14px!important;
    }
    .events_calendar_single_page_img_info_box_child2 {
        margin-top: 20px!important;
    }
    .add_to_calendar_event_btn {
        font-size: 14px!important;
    }
    .events_calendar_single_page_img_info_box_child1_title {
        font-size: 20px!important;
        margin-bottom: 5px!important;
    }
    .add_to_calendar_event_btn span {
        margin-right: 16px!important;
        width: 22px!important;
        height: 24px!important;
    }
    .add_to_calendar_event_btn span svg {
        width: 22px!important;
        height: 24px!important;
    }
    .events_calendar_single_page_img_info_box_child2_info {
        font-size: 16px!important;
    }
    .events_calendar_single_page_img_info_box_child_wrapper {
        flex-direction: column;
    }

    .events_calendar_single_page_img_info_box_child_wrapper {
        margin-bottom: 30px!important;
    }



    .events_calendar_single_page {
        padding-top: 34px!important;
    }
    .events_calendar_single_page_title_links_main_wrapper {
        padding-bottom: 15px!important;
    }
    .events_calendar_single_page_title {
        font-size: 21px!important;
    }
    .events_calendar_single_page_links_info_wrapper {
        margin-bottom: 20px!important;
    }
    .events_calendar_single_page_link {
        font-size: 12px!important;
    }
    .events_calendar_single_page_link_info {
        font-size: 12px!important;
    }
}

@media (max-width: 564px) {
    .events_calendar_single_page_link_info {
        display: none!important;
    }
}

@media (min-width: 902px) and (max-width: 1020px) {
    .russian_organizers_item_address_info {
        font-size: 16px!important;
    }
    .russian_organizers_item_phone_number_link {
        font-size: 16px!important;
    }
    .russian_organizers_item_phone_numbers_info_wrapper span {
        font-size: 16px!important;
    }
}

@media (max-width: 902px) {
    .russian_organizers_items_wrapper {
        flex-wrap: wrap;
    }
    .russian_organizers_item {
        width: 100% !important;
    }
    .russian_organizers_item:first-child {
        margin-bottom: 30px;
    }
}

@media (max-width: 769px) {
    .russian_contacts_items_wrapper {
        flex-wrap: wrap;
    }
    .russian_contacts_item {
        width: 100% !important;
    }
    .russian_contacts_item:first-child {
        margin-bottom: 30px;
    }
}


@media (min-width: 901px) and (max-width: 1566px) {
    .show_send_reminder_popup_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
    .show_send_reminder_popup_wrapper_child {
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .show_send_reminder_popup_form {
        width: 48% !important;
        max-width: unset!important;
    }
    .show_send_reminder_popup_form_img {
        max-width: unset!important;
        width: 48% !important;
    }
}
@media (max-width: 901px) {
    .show_send_reminder_popup_title2 {
        max-width: unset!important;
    }
    .show_send_reminder_popup_form_img_wrapper {
        flex-direction: column;
    }
    .show_send_reminder_popup_wrapper {
        max-width: unset!important;
        width: 90% !important;
        position: relative;
    }
    .show_send_reminder_popup_wrapper_child {
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .show_send_reminder_popup_form {
        width: 100% !important;
        max-width: unset!important;
        margin-bottom: 31px;
    }
    .show_send_reminder_popup_form_img {
        max-width: unset!important;
        width: 100% !important;
    }
    .show_send_reminder_popup_title_close_btn {
        position: absolute;
        right: 23px;
        top: 40px;
        z-index: 999;
    }
}

@media (max-width: 375px) {
    .show_send_reminder_popup_title_close_btn svg {
        width: 30px!important;
        height: 30px!important;
    }
}

.events_calendar_single_page_event_info_box {
    flex: 1;
}



.parsedHtml_event h1 {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml_event h1 span {
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml_event_active h1 {
    color:#C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml_event_active h1 span {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}

.parsedHtml_event_active h2 {
    color:#C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml_event_active h2 span {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 34.2px!important;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 22px;
    width: 100%;
    border-bottom: 0.8px solid #D4E4F2;
    margin-bottom: 45px;
}
.parsedHtml_event img {
    width: 100% !important;
    height: 399px;
    margin-bottom: 20px;
    object-fit: cover;
}
.parsedHtml_event_active img {
    width: 100% !important;
    height: 399px;
    margin-bottom: 20px;
    object-fit: cover;
}
.parsedHtml_event h3 {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml_event h3 span {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml_event h2 {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml_event h2 span {
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml_event p {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml_event p span {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml_event_active span {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
}

.parsedHtml_event_active h3 {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml_event_active h3 span {
    color: #C6C9CF;
    font-family: 'Evolventa', sans-serif;
    font-size: 22px!important;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.parsedHtml_event_active p {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml_event_active p span {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
}
.parsedHtml_event_active span {
    color: #C6C9CF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px!important;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
}

.error_text3 {
    color: #D61E3B;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    /*position: relative;*/
    /*bottom: 25px;*/
    /*margin-top: 10px;*/
    margin-top: 10px;
}

.show_send_reminder_popup_title_close_btn_wrapper2 {
    justify-content: flex-end!important;
}


