@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.events_calendar {
    width: 100%;
    border-top: 0.8px solid #D4E4F2;
    padding-top: 44px;
    /*margin-bottom: 57px;*/
}
.events_calendar_wrapper {
    width: 100%;
}
.events_calendar_wrapper_child1 {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.events_calendar_link_title_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.events_calendar_link {
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    text-decoration: none;
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    /*margin-right: 15px;*/
    color: #2F333A;

}
.events_calendar_link_icon {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.6px;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
}
.events_calendar_link_title {
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #2F333A;
}

.events_calendar_main_title_info_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}


.events_calendar_main_title {
    color: #2F333A;
    font-family: 'Evolventa',sans-serif;
    font-size: 32.3px;
    font-style: normal;
    font-weight: 700;
}


.events_calendar_info {
    font-family: 'Rawline',sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;

}

.events_calendar_search_dropdowns_main_wrapper {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.events_calendar_box {
    width: 100%;
    max-width: 540px;
}
.events_calendar_search_dropdowns_box {
    width: 100%;
    max-width: 540px;
}

.events_calendar_search_box {
    border-radius: 68px;
    border: 1px solid #2F333A;
    background: #FFF;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;
    cursor: pointer;
}
.events_calendar_search_input_field {
    width: 90%;
    background: none;
    border: none;
    color: rgba(108, 108, 108, 0.75);
    font-family: 'Rawline', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    outline: none;
    cursor: pointer;
}

.events_calendar_search_input_field::placeholder {
    color: rgba(108, 108, 108, 0.75);
    font-family: 'Rawline', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.events_calendar_search_input_field_dark {
    width: 90%;
    background: none;
    border: none;
    color: #A7A8BA;
    font-family: 'Rawline', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    outline: none;
}
.events_calendar_search_input_field_dark::placeholder {
    color: #A7A8BA;
    font-family: 'Rawline', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.events_calendar_search_btn {
    border: none;
    background: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
}

.events_calendar_dropdown_wrapper {
    border-radius: 6px;
    background: #2F333A;
    width: 100%;
    padding: 36px 24px 50px 24px;
    margin-bottom: 20px;
    position: relative;
}

.events_calendar_dropdown_title {
    color: #FFF;
    font-family: 'Rawline',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 14px;
}

.events_calendar_dropdown_btn_title_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background: #FFF;
    padding: 21px 10px;
    cursor: pointer;
}

.events_calendar_dropdown_btn_text {
    color: rgba(108, 108, 108, 0.75);
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.events_calendar_dropdown_btn {
    border: none;
    background: none;
    height: 20px;
    outline: none;
    cursor: pointer;
}

.events_calendar_dropdown {
    position: absolute;
    border-radius: 6px;
    border: 1px solid #B8B8B8;
    background: #FFF;
    box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.10);
    width: 100%;
    max-width: 492px;
    padding: 19px 0;
    top: 145px;
    z-index: 9999;
}

.events_calendar_dropdown_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 12px 16px;
}
.events_calendar_dropdown_info:hover {
    background: #E71E24;
    color: #FFFFFF;
}

.events_calendar_wrapper_child2 {
    width: 100%;
}
.events_items_main_wrapper_parent:first-of-type {
    background: rgba(212, 228, 242, 0.30);
}
.events_items_main_wrapper_parent {
    width: 100%;
}

.events_items_main_wrapper {
    padding: 57px 0;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.load_more_btn_pagination_wrapper {
    width: 100%;
    max-width: 288px;
    margin: 0 auto;
}
.events_items_main_title {
    color: rgba(47, 51, 58, 0.85);
    font-family: 'Evolventa', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.events_item {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 2px 1px 39px 0px rgba(0, 0, 0, 0.06);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    outline: none;
    padding: 32px;
    text-decoration: none;
    margin-bottom: 30px;
    position: relative;
    border: none;
    text-align: left;
}
.events_item:after {
    content: '';
    position: absolute;
    background: #E71E24;
    width: 10px;
    height: 100%;
    z-index: 999;
    border-radius: 6px 0px 0px 6px;
    left: 0;
    top: 0
}
.events_item_img {
    border-radius: 6px;
    border: 1px solid #D4E4F2;
    /*background: #FFF;*/
    width: 141px;
    height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 34px;
    cursor: pointer;
    outline: none;
    overflow: hidden;

}
.events_item_img img{
    /*width: 120px;*/
    /*height: 80px;*/
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.events_item_info_box_title {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 12px;
}
.events_item_info_box_event_name {
    color: #E71E24;
    font-family: 'Rawline',sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px;
}

.events_item_info_box_info1 {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 29px;
}

.events_item_info_box_title2 {
    color: rgba(47, 51, 58, 0.60);
    font-family: 'Rawline',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
}

.events_item_info_box_info2 {
    color: rgba(47, 51, 58, 0.75);
    font-family: 'Rawline',sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.events_item_info_box_age_date_info_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 8px;
}


.events_item_info_box .events_item_info_box_child:first-child {
    width: 100%;
    max-width: 477px;
    margin-right: 77px;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    text-align: left;
}
.events_item_info_box .events_item_info_box_child:nth-child(2) {
    width: 100%;
    max-width: 242px;
}
.events_item_info_box {
    width: 100%;
    max-width: 848px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
}

.events_item_info_box_age_info {
    border-radius: 66px;
    border: 2px solid rgba(196, 199, 204, 0.60);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 22px;
    color: #C4C7CC;
    font-family: 'Rawline',sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
}

.events_item_info_box_date_info {
    color: #E71E24;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.events_item_days_until_completion_info {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    width: 144px;
    margin: 0  auto;
    margin-bottom: 13px;
}
.add_event_to_calendar_btn {
    width: 201px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    position: relative;
    left: 16px;
    margin-bottom: 20px;
}

.add_event_to_calendar_btn_title {
    color: #0058A9;
    font-family: 'Rawline',sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-decoration-line: underline;
    margin-left: 14px;
}

.event_send_a_reminder_btn {
    border-radius: 5px;
    background: #E71E24;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    width: 242px;
    position: relative;
    left: 42px;
    border: none;
    color: #FFF;
    font-family: 'Rawline',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.event_send_a_reminder_btn:hover {
    background: #D00000;
}
.load_more_events_btn {
    border-radius: 6px;
    border: 1px solid #E71E24;
    background: #E71E24;
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
}
.load_more_events_btn:hover {
    background: #D00000;
}

.load_more_btn_pagination_wrapper {
    padding-top: 40px;
    padding-bottom: 42px;

}

.load_more_events_btn span {
    margin-left: 15px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.events_items_pagination_wrapper {
    display: flex;
    width: 187px;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.events_items_pagination_btn {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

}

.events_items_pagination_btn:hover svg path {
    fill: #E71E24;
}
.events_items_pagination_btn_active {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.events_items_pagination_btn_active svg path {
    fill: #E71E24;
}
.events_items_pagination_link {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    color: #C6C6C6;
    text-align: center;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.events_items_pagination_link:hover {
    border-radius: 3px;
    border: 1px solid #D4E4F2;
    color: #E71E24;
}
.events_items_pagination_link_info {
    color: #C6C6C6;
    text-align: center;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-right: 8px;
}
.events_items_pagination_link_active {
    border-radius: 3px;
    border: 1px solid #D4E4F2;
    color: #E71E24;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    text-align: center;
    font-family: 'Rawline',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    width: 32px;
    height: 32px;
    margin-right: 8px;

}

.events_items_pagination_links_wrapper {
    margin-right: 44px;
    margin-left: 44px;
    display: flex;
    align-items: center;
}

.mobile_events_item_info_box_age_info {
    display: none;
    border-radius: 66px;
    border: 2px solid rgba(196, 199, 204, 0.60);
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    color: #C4C7CC;
    font-family: 'Rawline',sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
}
.events_calendar_link_title_main_title_main_wrapper {
    width: 100%;
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 0.8px solid #D4E4F2;
}
.calendar_mobile_line {
    display: none;
    width: 100%;
    height: 1px;
    background: #D4E4F2;
    margin-bottom: 30px;
}
/*media*/

@media (min-width: 769px) and (max-width: 1120px) {
    .events_calendar_wrapper_child1 {
        max-width: unset!important;
        width: 95% !important;
    }
    .events_items_main_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media (min-width: 769px) and (max-width: 1120px) {
    .events_calendar_box {
        max-width: unset!important;
        width: 49%!important;
    }
    .events_calendar_search_dropdowns_box {
        max-width: unset!important;
        width: 49%!important;
    }
}
@media (min-width: 769px) and (max-width: 980px) {
    .calendar_child {
        width: 100% !important;
        margin: 0 auto;
    }
    .Calendar {
        margin: 0 auto!important;
    }
}

@media  (max-width: 769px) {
    .events_calendar_link_title_main_title_main_wrapper {
        border-bottom: unset!important;
    }
    .calendar_mobile_line {
        display: none!important;
    }
    .calendar_child {
        width: 100% !important;
        margin: 0 auto;
    }
    .Calendar {
        width: 100% !important;
        margin: 0 auto!important;
    }
    .events_item {
        border: none!important;

    }

    .events_item:after {
      display: none!important;
    }
    .events_item_img {
        position: relative;
    }
    .events_item_img:after {
        content: '';
        position: absolute;
        width: 28px!important;
        height: 28px!important;
        background: #E71E24!important;
        border-radius: 100% !important;
        /*left: -42%!important;*/
        top: -6%!important;
        z-index: 999;
    }
    /*.calendar_mobile_line {*/
    /*    display: block!important;*/
    /*}*/
    .events_items_main_title {
        padding-right: 30px;
        padding-left: 30px;
    }
    .events_calendar_wrapper_child1 {
        max-width: unset!important;
        width: 100% !important;
    }
    .events_items_main_wrapper {
        max-width: unset!important;
        width: 100% !important;
        /*padding-right: 30px;*/
        /*padding-left: 30px;*/
    }
    .events_calendar_link_title_wrapper {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
    }
    .events_calendar_main_title_info_wrapper {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: flex-start!important;
    }

    .events_calendar_main_title_info_wrapper {
        padding-right: 30px;
        padding-left: 30px;
        width: 100%;
        margin-bottom: 14px;
    }

}

@media (min-width: 1080px) and (max-width: 1110px) {
    .events_item_info_box .events_item_info_box_child:first-child {
        margin-right: 45px!important;
    }
    .events_item {
        padding: 32px 22px!important;
    }
}
@media (min-width: 961px) and  (max-width: 1080px) {
    .events_item_info_box .events_item_info_box_child:first-child {
        margin-right: 20px!important;
    }
    .events_item {
        padding: 32px 22px!important;
    }
    .events_item_img {
        margin-right: 20px!important;
    }
}

@media (min-width: 960px) and (max-width: 1030px) {
    .events_item_info_box .events_item_info_box_child:first-child {
        max-width: 437px!important;
    }
    .events_item_info_box_info1 {
        font-size: 16px!important;
    }
    .events_item_info_box_info2 {
        font-size: 14px!important;
    }
    .event_send_a_reminder_btn {
        width: 220px!important;
    }
}


@media (min-width: 769px) and (max-width: 960px) {
    .events_item_info_box_age_info {
        margin-right: 15px!important;
    }
    .events_item {
        flex-direction: column;
        justify-content: space-between;
    }
    .events_item_info_box .events_item_info_box_child:first-child {
        margin-right: unset!important;
        max-width: unset!important;
        width: 63%!important;

    }
    .events_item_info_box .events_item_info_box_child:nth-child(2) {
        max-width: unset!important;
        width: 33% !important;
    }
    .events_item {
        padding: 32px 22px!important;
    }
    .events_item_img {
        margin-right: unset!important;
        margin-bottom: 20px;
        width: 200px!important;
    }
    .events_item_info_box {
        max-width: unset!important;
        justify-content: space-between!important;
    }
    .event_send_a_reminder_btn {
        width: 100% !important;
        position: unset!important;

    }
    .events_item_info_box_date_info {
        font-size: 16px!important;
    }
}


@media (max-width: 769px) {
    .events_calendar_info {
        border-bottom: 1px solid #E3E4EA;
        width: 85%;
        padding-bottom: 14px;
    }
    .events_items_main_wrapper {
        padding: 50px 0!important;
    }
    .events_item_info_box_age_info {
        display: none!important;
    }
    .mobile_events_item_info_box_age_info {
        display: flex!important;
    }
    .events_item_info_box_date_info {
        margin-right: 20px!important;
    }
    .events_item {
        flex-direction: column;
        justify-content: space-between;
    }
    .events_item_info_box .events_item_info_box_child:first-child {
        margin-right: unset!important;
        max-width: unset!important;
        width: 100%!important;
        margin-bottom: 30px!important;

    }
    .events_item_info_box .events_item_info_box_child:nth-child(2) {
        max-width: 300px!important;
        width: 100% !important;
    }
    .events_item {
        padding: 30px!important;
    }
    .events_item_img {
        margin-right: unset!important;
        margin-bottom: 20px;
        width: 200px!important;
    }
    .events_item_info_box {
        max-width: unset!important;
        justify-content: space-between!important;
        flex-direction: column;
    }
    .event_send_a_reminder_btn {
        width: 100% !important;
        position: unset!important;

    }
    .events_item_days_until_completion_info {
        margin: unset!important;
        width: unset!important;
        margin-bottom: 13px!important;
    }
    .add_event_to_calendar_btn {
        width: unset!important;
        margin: unset!important;
        margin-bottom: 20px!important;
        position: unset!important;
    }
    .events_item_info_box_age_date_info_wrapper {
        justify-content: flex-start!important;
    }

    .events_item_info_box_date_info {
        margin-right: 15px;
    }
    .events_calendar_search_dropdowns_main_wrapper {
        flex-direction: column;
    }
    .events_calendar_box {
        max-width: unset!important;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        /*padding-left: 30px;*/
        /*padding-right: 30px;*/

    }
    .events_calendar_search_dropdowns_box {
        padding-left: 30px;
        padding-right: 30px;
        max-width: unset!important;
    }

}


@media (min-width: 426px) and (max-width: 769px) {
    .events_calendar_box {
        margin-bottom: 30px!important;
    }
    .events_calendar_link {
        font-size: 14px!important;
    }
    .events_calendar_link_title {
        font-size: 14px!important;
    }
    .events_calendar_main_title {
        font-size: 28px!important;
    }
    .events_calendar_info {
        font-size: 16px!important;
    }
    .events_calendar_link_title_main_title_main_wrapper {
        margin-bottom: 30px!important;
    }
    .events_calendar_search_input_field {
        font-size: 16px!important;
    }
    .events_calendar_search_input_field::placeholder {
        font-size: 16px!important;
    }
    .events_calendar_search_input_field_dark {
        font-size: 16px!important;
    }
    .events_calendar_search_input_field_dark::placeholder {
        font-size: 16px!important;
    }
    .events_calendar_search_box {
        padding: 15px 17px!important;
        border-radius: 50px!important;
    }
    .events_calendar_dropdown_wrapper {
        border-radius: 5px;
        padding: 25px 18px 35px 18px;
    }
    .events_calendar_dropdown_btn_text {
        font-size: 16px!important;
    }
    .events_calendar_dropdown_info {
        font-size: 16px!important;
        margin-bottom: 8px!important;
        padding: 8px 12px!important;
    }
    .events_items_main_title {
        font-size: 29px!important;
    }
    .events_item_info_box_info1 {
        font-size: 16px!important;
        margin-bottom: 22px!important;
    }
    .load_more_events_btn {
        font-size: 17px!important;
    }
}
@media (max-width: 426px) {
    .events_item_img:after {
        top: -11% !important
    }
    .events_calendar_dropdown_btn svg {
        width: 15px!important;
        height: 18px!important;
    }
    .events_calendar_dropdown_btn {
        height: 18px!important;
    }
    .events_calendar_dropdown_btn_title_wrapper {
        padding: 15px 6px!important;
    }
    .events_calendar {
        padding-top: 34px!important;
        /*margin-bottom: 45px!important;*/
    }
    .load_more_events_btn {
        font-size: 15px!important;
        margin-bottom: 30px!important;
    }
    .events_item_info_box .events_item_info_box_child:nth-child(2) {
        max-width: 280px!important;
    }
    .event_send_a_reminder_btn {
        font-size: 15px!important;
    }
    .add_event_to_calendar_btn_title {
        font-size: 14px!important;
    }
    .events_item_days_until_completion_info {
        font-size: 14px!important;
    }
    .events_item_info_box_info2 {
        font-size: 14px!important;
    }
    .events_item_info_box_info1 {
        font-size: 14px!important;
        margin-bottom: 19px!important;
    }
    .events_item_info_box_event_name {
        font-size: 18px!important;
    }
    .events_item_img {
        width: 141px!important;
        height: 103px!important;
        margin-bottom: 17px!important;
    }
    .events_item {
        margin-bottom: 20px!important;
    }

    .events_items_main_wrapper {
        padding: 50px 0 30px 0!important;
    }

    .events_items_main_title {
        font-size: 25px!important;
    }
    .events_calendar_dropdown {
        padding: 14px 0!important;
    }
    .events_calendar_dropdown_info {
        font-size: 14px!important;
        margin-bottom: 5px!important;
        padding: 6px 10px!important;
    }
    .events_calendar_dropdown_btn_text {
        font-size: 14px!important;
    }
    .events_calendar_dropdown_title {
        font-size: 12px!important;
        margin-bottom: 7px!important;
    }
    .events_calendar_dropdown_wrapper {
        border-radius: 3px;
        padding: 11px 14px 22px 14px;
    }
    .events_calendar_search_btn {
        height: 14px;
    }
    .events_calendar_search_btn svg {
        width: 14px!important;
        height: 14px!important;
    }
    .events_calendar_search_box {
        padding: 11px 14px!important;
        border-radius: 37px!important;
    }
    .events_calendar_search_input_field {
        font-size: 14px!important;
    }
    .events_calendar_search_input_field::placeholder {
        font-size: 14px!important;
    }
    .events_calendar_box {
        margin-bottom: 20px!important;
    }
    .events_calendar_link {
        font-size: 12px!important;
        margin-right: 13px!important;
    }
    .events_calendar_link_title {
        font-size: 14px!important;
    }
    .events_calendar_link_title_wrapper {
        margin-bottom: 22px!important;
    }
    .events_calendar_main_title {
        font-size: 20px!important;
    }
    .events_calendar_info {
        font-size: 13px!important;
    }
    .events_calendar_link_title_main_title_main_wrapper {
        margin-bottom: 30px!important;
    }
    .events_calendar_link_title_main_title_main_wrapper {
        margin-bottom: 20px!important;
    }
    .events_calendar_search_dropdowns_main_wrapper {
        margin-bottom: 48px!important;
    }
}



/*.events_calendar_link:link {*/
/*    cursor: pointer;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    outline: none;*/
/*    text-decoration: none;*/
/*    font-family: 'Rawline', sans-serif;*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*    letter-spacing: 0em;*/
/*}*/

/*.events_calendar_link:visited {*/
/*    cursor: pointer;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    outline: none;*/
/*    text-decoration: none;*/
/*    font-family: 'Rawline', sans-serif;*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*    letter-spacing: 0em;*/
/*}*/


/*.events_calendar_link:active {*/
/*    cursor: pointer;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    outline: none;*/
/*    text-decoration: none;*/
/*    font-family: 'Rawline', sans-serif;*/
/*    font-size: 16px;*/
/*    font-weight: 400;*/
/*    letter-spacing: 0em;*/
/*}*/


.calendar_main_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}
.calendar_main_wrapper_parent {
    width: 100%;
    border: 1px solid #D4E4F2;
    padding: 30px 24px;
}


.calendar_child {
    width: 48%;
}

.calendar_show_btn {
    background: #E71E24;
    width: 180px;
    height: 48px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    font-family: 'Rawline',sans-serif;
    margin-top: 36px;
    margin-bottom: 26px;
}
.calendar_show_btn:hover {
    background: #D00000;
}

.shown_dates {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Rawline',sans-serif;
    color: #343747;
    text-align: center;
    padding-top: 26px;
}

.react-calendar__tile--active {
    background: #E71E24!important;
    color: white!important;
    font-size: 14px!important;
    font-weight: 700!important;
    font-family: 'Rawline',sans-serif!important;
}

.react-calendar__tile {
    color: #383838;
    font-size: 14px!important;
    font-weight: 400!important;
    font-family: 'Rawline',sans-serif!important;

}

.react-calendar__month-view__weekdays {
    padding-bottom: 10px!important;
    border-bottom: 1px solid #D2D6D9;
    color: #8A8F99!important;
    font-weight: 400!important;
    font-size: 14px!important;
}

.react-calendar {
    border: none!important;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    display: flex!important;
    color: #383838!important;
    font-weight: 600!important;
    font-size: 16px!important;
     min-width: 100px!important;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}
.react-calendar__navigation button {
    min-width: 27px!important;
    background: none;
}
.react-calendar__navigation__arrow {
    color: #E71E24!important;
    font-size: 20px!important;

}

.Calendar__monthText {
    color: #383838!important;
    font-weight: 600!important;
    font-size: 16px!important;
    font-family: 'Rawline',sans-serif!important;
}
.Calendar__yearText {
    color: #383838!important;
    font-weight: 600!important;
    font-size: 16px!important;
    font-family: 'Rawline',sans-serif!important;
}

.Calendar__day {
    color: #383838!important;
    font-weight: 400!important;
    font-size: 14px!important;
    font-family: 'Rawline',sans-serif!important;
}

.Calendar__monthArrow {
    background-image: unset!important;
    width: 10px!important;
    height: 10px!important;
}

.Calendar__monthArrowWrapper.-right{
    background-image: url("../svg/arrow_right.svg") !important;
    transform: unset!important;
    background-repeat: no-repeat!important;
}



.Calendar__monthArrowWrapper.-left{
    background-image: url("../svg/arrow_left.svg") !important;
    transform: unset!important;
    background-repeat: no-repeat!important;
}

.custom-today-day {
    color: #e67e22 !important;
    border: 1px solid #e67e22 !important;
}

.custom-today-day::after {
    visibility: hidden; /* hide small border under the text */
}

.active-day {
    /*border-bottom: 2px solid red!important;*/
    position: relative;
}

.active-day:after {
    position: absolute;
    content: '';
    z-index: 999;
    width: 23px;
    height: 1px;
    background: red;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
