@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.not_found_page {
  width: 100%;
  padding-top: 31px;
  padding-bottom: 44px;
}

.not_found_page_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.not_found_page_img {
    width: 165px;
    height: 77px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 83px;
}
.not_found_page_img img {
    width: 165px;
    height: 77px;
    object-fit: cover;
}

.not_found_page_title {
    color: #E71E24;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 76px;
    font-style: normal;
    font-weight: 700;
    line-height: 121.6px; /* 160% */
    margin-bottom: 20px;
}
.not_found_page_title span {
  color: #2F333A;
}
.not_found_page_info {
    color: #2F333A;
    text-align: center;
    font-family: 'Rawline',sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.4px;
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 41px;
}

.not_found_page_link {
    border-radius: 6px;
    background: #E71E24;
    width: 181px;
    height: 68px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa',sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 138px;
    text-decoration: none;
}

.not_found_page_info_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.not_found_page_info2 {
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}

/*media*/

@media (min-width: 426px) and (max-width: 1120px) {
    .not_found_page_wrapper {
        width: 95% !important;
        max-width: unset!important;
    }
}
@media  (max-width: 426px) {
    .not_found_page_wrapper {
        width: 100%!important;
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .not_found_page {
        padding-top: 16px!important;
        padding-bottom: 30px!important;
    }

}




@media (max-width: 490px) {
    .not_found_page_link {
        font-size: 15px!important;
        height: 54px!important;
    }
    .not_found_page_info {
        font-size: 15px!important;
        margin-bottom: 33px!important;
        line-height: 24px!important;
    }
    .not_found_page_title {
        font-size: 60px!important;
        margin-bottom: 15px!important;
        line-height: 96px!important;
    }
    .not_found_page_img {
        width: 112px!important;
        height: 53px!important;
    }
    .not_found_page_img img{
        width: 112px!important;
        height: 53px!important;
    }
    .not_found_page_info_wrapper {
        flex-direction: column;
    }
    .not_found_page_info2 {
        font-size: 12px!important;
    }
    .not_found_page_info2:first-child {
        margin-bottom: 11px!important;
    }

}
