@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}





* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer {
    width: 100%;
    padding-bottom: 38px;
    padding-top: 57px;
}

.footer_wrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
}

.footer_first_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 42px;
}

.footer_first_item_child1 {
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    padding: 27px;
    /*max-width: 729px;*/
    width: 100%;
}
.footer_logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    width: 97px;
    height: 46px;
    margin-right: 30px;
}

.footer_logo img {
    width: 100%;
    height: 100%;
}

.footer_info1 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 17.9px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.58px;
    margin-bottom: 25px;
    width: 98%;
}

.footer_social_links_wrapper {
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;

}

.footer_line {
    height: 1px;
    width: 100%;
    background: #D4E4F2;
    margin-bottom: 18px;
}
.footer_social_link {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
    justify-content: center;
}


.connect_with_us_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.connect_with_us_title {
    display: flex;
    /*align-items: center;*/
    color: #E71E24;
    font-family: 'Rawline', sans-serif;
    font-size: 17.9px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.58px;
}
.connect_with_us_title_icon  {
    margin-left: 7px;
    height: 16px;
    position: relative;
    top: 3px;
}
.connect_with_us_privacy_policy_link {
    color: #2F333A;
    text-align: right;
    font-family: 'Rawline', sans-serif;
    font-size: 16.7px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.75px;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}

.footer_first_item_child2 {
    max-width: 341px;
    width: 100%;
}
.footer_categories_dropdown-wrapper {
    border-radius: 8px;
    border: 1px solid #D4E4F2;
    width: 100%;
    padding: 25px;
    position: relative;
}

.footer_categories_dropdown-wrapper:first-child {
    margin-bottom: 30px;
}

.footer_categories_dropdown_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.32px;
    text-transform: uppercase;
    margin-bottom: 22px;
}

.footer_categories_dropdown_btn {
    border-radius: 6px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    padding: 9px 11px;
    cursor: pointer;
}


.footer_categories_dropdown_btn_text {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 17.1px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.65px;
}

.footer_categories_dropdown {
    position: absolute;
    border-radius: 6px;
    border: 1px solid #B8B8B8;
    background: #FFF;
    box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.10);
    width: 286px;
    padding: 6px 0;
    top: 130px;
    z-index: 9999;
}

.footer_categories_dropdown_info {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 17.1px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 5px 11px;
}
.footer_categories_dropdown_info:hover {
    background: #E71E24;
    color: #FFFFFF;
}

.footer_second_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer_info2 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15.2px;
    font-style: normal;
    font-weight: 400;
}
.footer_info3 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}
.footer_info4 {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.company_link {
    display: inline-block;
    cursor: pointer;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-left: 5px;
}

.footer_logo_img_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

}
.footer_img {
    width: 100%;
    max-width: 223px;
    height: 60px;
}
.footer_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mobile_footer_second_item {
    display: none;
}

.mobile_footer_img {
    display: none;
}
/*mobile*/

@media (min-width: 426px) and (max-width: 1120px) {
    .footer_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}
@media  (max-width: 426px) {
    .footer_wrapper {
        max-width: unset!important;
        width: 100%!important;
        padding-right: 30px!important;
        padding-left: 30px!important;
    }
}
@media (min-width: 768px) and (max-width: 1120px) {
    .footer_first_item_child1 {
        max-width: unset!important;
        /*width: 67% !important;*/
    }
    .footer_first_item_child2 {
        max-width: unset!important;
        width: 30% !important;
    }
}

@media (min-width: 426px) and (max-width: 950px) {
    .footer_first_item_child1 {
        padding: 27px 20px!important;
    }
    .footer_info1 {
        font-size: 15px!important;
    }
    .footer_categories_dropdown-wrapper {
        padding: 25px 20px!important;
    }
}

@media (max-width: 768px) {
    .headers_navigation_link {
        border: none;
        background: none;
    }
    .footer_first_item {
        flex-direction: column-reverse;
    }
    .footer_first_item_child1 {
        max-width: unset!important;
        width: 100% !important;
    }
    .footer_first_item_child2 {
        max-width: unset!important;
        width: 100% !important;
        margin-bottom: 30px;
    }
    .footer_second_item {
        flex-direction: column;
    }
    .mobile_footer_second_item {
        display: flex!important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .footer_second_item {
        display: none!important;
    }
    .footer_info3 {
        margin-bottom: 21px!important;
    }
    .footer_info2 {
        margin-bottom: 21px!important;
    }
}

@media (max-width: 525px) {
    .connect_with_us_wrapper {
        flex-direction: column;
        justify-content: flex-start!important;
        align-items: flex-start!important;
    }
    .connect_with_us_title {
        margin-bottom: 5px;
    }
    .mobile_footer_img {
        display: block!important;
        width: 134px;
        height: 50px;
    }
    .mobile_footer_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .footer_img {
        display: none!important;
    }
    .footer_logo {
        width: 112px!important;
        height: 53px!important;
        margin-right: 10px!important;
    }

}

@media (max-width: 426px) {
    .company_link {
        font-size: 12px!important;
    }
    .footer_first_item {
        margin-bottom: 20px!important;
    }
    .footer_first_item_child1 {
        padding: 22px!important;
    }
    .footer_info1 {
        font-size: 13.5px!important;
        line-height: 21.6px!important;
        margin-bottom: 20px!important;
    }
    .footer_categories_dropdown-wrapper {
        padding: 22px!important;
    }
    .footer_categories_dropdown_title {
        font-size: 12px!important;
        margin-bottom: 21px!important;
    }
    .footer_categories_dropdown_btn_text {
        font-size: 13.5px!important;
    }

    .footer_categories_dropdown_info {
        font-size: 14px!important;
    }
    .footer_categories_dropdown_btn {
        border: 1px solid #C9C5DB!important;
        padding: 6px 10px!important;
    }
    .footer_logo_img_wrapper {
        margin-bottom: 20px!important;
    }
    .footer_social_links_wrapper {
        margin-bottom: 20px!important;
    }
    .footer_line {
        margin-bottom: 14px!important;
    }
    .connect_with_us_title {
        font-size: 15px!important;
    }
    .connect_with_us_privacy_policy_link {
        font-size: 14px!important;
    }
    .footer_info3 {
        font-size: 12px!important;
    }
    .footer_info2 {
        font-size: 12px!important;
    }
    .footer_info4 {
        font-size: 12px!important;
    }
}


@media (max-width: 360px) {
    .mobile_footer_img {
        height: unset!important;
    }
}


.vk_footer_social_link {
    background: #0077FF;
}
.youtube_footer_social_link {
    background: #E71E24;
}
.telegram_footer_social_link {
    background: #0088CC;

}
.dzen_footer_social_link {
    background: #333333;

}

