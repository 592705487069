@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}





* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    width: 100%;
    margin-bottom: 15px;
}
.header_wrapper {
    width: 100%;
}
.header_first_item {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 25px;
    border-bottom:  0.8px solid #D4E4F2;
    margin-bottom: 15px;
}

.header_first_item_wrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_second_item {
    width: 100%;
    /*max-width: 758px;*/
    max-width: 900px;
    margin: 0 auto;

}
.headers_navigation_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header_social_links_wrapper {
    width: 100%;
    max-width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_social_link {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 100%;

}

.header_logo {
    display: flex;
    width: 165px;
    height: 77px;
    cursor: pointer;
    outline: none;
    align-items: center;
    justify-content: center;
}
.header_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header_search_input_buttons_wrapper {
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.header_search_form {
    border-radius: 19px;
    border: 1px solid #D4E4F2;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 275px;
    padding: 9px 11px 10px 21px;
    cursor: pointer;
    height: 38px;
}
.header_search_form_input_field {
    color: rgba(108, 108, 108, 0.75);
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border: none;
    outline: none;
}
.header_search_form_input_field::placeholder {
    color: rgba(108, 108, 108, 0.75);
    font-family: 'Rawline', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.header_search_form_button {
    height: 30px;
    border: none;
    background: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.header_personal_area_btn {
    border-radius: 19px;
    background: #E71E24;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
}
.header_personal_area_btn:hover {
    background: #D00000;
}
.header_mode_btn:hover {
    background: #D00000;
}
.header_mode_btn {
    border-radius: 19px;
    background: #E71E24;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
}

.headers_navigation_link {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.headers_navigation_link2 {
    color: #ffffff;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_dropdown_wrapper {
    position: relative;
    width: 150px;
    padding: 15px 0;
}
.mobile_header_dropdown_button {
    display: none;
}
.header_dropdown_wrapper:hover .header_dropdown{
    display: block!important;
}
.header_dropdown_wrapper:hover .mobile_header_dropdown_button{
    display: flex!important;
}
.header_dropdown_wrapper:hover .header_dropdown_button{
    display: none!important;
}

.mobile_header_dropdown_button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    height: 13px;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
}
.header_dropdown_title_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_dropdown_button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
}

.header_dropdown {
    background: #2F333A;
    position: absolute;
    top: 60px;
    left: -32px;
    width: 200px;
    padding: 36px 35px 37px 35px;
    z-index: 9999;
    display: none;
}

.header_dropdown_icon {
    position: absolute;
    top: -16px;
    left: 49px;
}
.header_dropdown_title {
    color: #2F333A;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
}
.header_dropdown_title2 {
    color: #FFFFFF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
}
.header_dropdown_info:hover {
    font-weight: 600!important;
    font-size: 20px!important;
}

.header_dropdown_info {
    color: #FFF;
    font-family: 'Rawline', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    text-decoration: none;
}
.header_dropdown_info:not(:last-child) {
    margin-bottom: 20px;
}


/*mobile*/

@media   (max-width: 1120px) {
    .header_first_item_wrapper {
        max-width: unset!important;
        width: 95%!important;
    }

}

@media (min-width: 870px) and (max-width: 930px) {
    .header_dropdown_title {
        font-size: 16px!important;
    }
    .header_dropdown_title2 {
        font-size: 16px!important;
    }
    .header_second_item {
        max-width: unset!important;
        width: 83% !important;
    }
    .headers_navigation_link {
        font-size: 16px!important;
    }
    .headers_navigation_link2 {
        font-size: 16px!important;
    }
}
@media (min-width: 768px) and (max-width: 870px) {
    .header_dropdown_wrapper {
        position: relative;
        width: 110px!important;
    }
    .header_dropdown_title {
        font-size: 14px!important;
    }
    .header_dropdown_title2 {
        font-size: 14px!important;
    }
    .header_dropdown_title2 {
        font-size: 14px!important;
    }
    .header_second_item {
        max-width: unset!important;
        width: 82% !important;
    }
    .headers_navigation_link {
        font-size: 14px!important;
    }
    .headers_navigation_link2 {
        font-size: 14px!important;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .header_logo {
        width: 150px!important;
        height: 70px!important;
    }
    .header_search_input_buttons_wrapper {
        max-width: 370px!important;
    }
}

.mobile_header_wrapper {
    display: none;
}

@media (max-width: 768px) {
    .header_dropdown_wrapper {
        width: 100% !important;
    }
    .header_dropdown {
        padding: 25px 25px 31px 25px!important;
        border-radius: 6px!important;
        width: 100% !important;
        left: unset!important;
    }
    .header {
        margin-bottom: unset!important;
    }
    .header_wrapper {
        display: none!important;
    }
    .mobile_header_wrapper {
        display: block!important;
        padding-top: 18px;
    }
    .mobile_header_first_item {
        width: 100%;
        padding-bottom: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mobile_logo_link {
        display: flex;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        width: 113px;
        height: 53px;
    }
    .mobile_logo_link img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .mobile_header_line {
        background: #E3E4EA;
        height: 1px;
        width: 100%;
    }
    .mobile_header_second_item {
        width: 250px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .mobile_second_item_btn {
        width: 38px;
        height: 38px;
        border-radius: 19px;
        background: #E71E24;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
    }
    .mobile_second_item_btn:hover {
        background: #D00000;
    }

    .headers_navigation_links_wrapper {
        width: 100%;
        flex-direction: column!important;
        align-items: flex-start!important;

    }
    .headers_navigation_link {
        margin-bottom: 25px;
        font-size: 18px!important;
    }
    .headers_navigation_link2 {
        margin-bottom: 25px;
        font-size: 18px!important;
    }

    .header_dropdown_title {
        font-size: 18px!important;
    }
    .header_dropdown_title2 {
        font-size: 18px!important;
    }
    .header_dropdown_title_button_wrapper {
        justify-content: flex-start!important;
    }
    .header_search_form {
        border-radius: 38px!important;
        border: 1px solid #2F333A!important;
        background: #FFF!important;
        height: 50px!important;
    }
    .header_social_links_wrapper {
        max-width: unset!important;
    }
}



.hamburger_menu_popup {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(23, 24, 30, 0.96);
    /*backdrop-filter: blur(20px);*/
}

.hamburger_menu_popup_wrapper {
    width: 100%;
    background: #FFF;
    max-width: 360px;
    padding: 115px 30px 57px 30px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    overflow: scroll;
}

.hamburger_menu_popup_close_btn {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 28px;
    top: 43px;
    z-index: 99;
}
.header_search_popup {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(23, 24, 30, 0.96);
    /*backdrop-filter: blur(20px);*/
}
.header_search_popup_wrapper {
    width: 100%;
    padding: 106px 30px 57px 30px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    overflow: scroll;
}
.header_search_popup_close_btn {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 28px;
    top: 28px;
    z-index: 99;
}
.header_social_networks_popup {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(23, 24, 30, 0.96);
    /*backdrop-filter: blur(20px);*/
}
.header_social_networks_popup_wrapper {
    width: 100%;
    background: #FFF;
    max-width: 360px;
    padding: 115px 30px 57px 30px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    overflow: scroll;
}
.header_social_networks_popup_close_btn {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 28px;
    top: 28px;
    z-index: 99;
}
@media (max-width: 425px) {
    .header_dropdown {
        padding: 25px 25px 31px 25px!important;
    }
    .header_dropdown_info {
        font-size: 18px!important;
    }
 .hamburger_menu_popup_wrapper {
     max-width: unset!important;
 }
}

.mobile_header_second_item_wrapper {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 15px;

}

.header_social_links_main_wrapper {
    max-width: 400px;
    width: 100%;
}


@media (min-width: 900px) and (max-width: 1051px) {
    .header_social_links_main_wrapper {
        max-width: 320px!important;
    }
    .header_search_form {
        max-width: 240px!important;
    }
    .header_search_input_buttons_wrapper {
        max-width: 340px!important;
    }
    .header_personal_area_btn {
        width: 35px!important;
        height: 35px!important;
    }
    .header_mode_btn {
        width: 35px!important;
        height: 35px!important;
    }
    .header_personal_area_btn img {
        width: 35px!important;
        height: 35px!important;
    }
    .header_mode_btn  img{
        width: 24px!important;
        height: 24px!important;
    }
}
@media (min-width: 800px) and  (max-width: 900px) {
    .header_social_links_main_wrapper {
        max-width: 290px!important;
    }
    .header_search_form {
        max-width: 221px!important;
    }
    .header_search_input_buttons_wrapper {
        max-width: 300px!important;
    }
    .header_personal_area_btn {
        width: 30px!important;
        height: 30px!important;
    }
    .header_mode_btn {
        width: 30px!important;
        height: 30px!important;
    }
    .header_personal_area_btn img {
        width: 30px!important;
        height: 30px!important;
    }
    .header_mode_btn  img{
        width: 22px!important;
        height: 22px!important;
    }
    .header_search_form_input_field {
        font-size: 13px!important;
        width: 76% !important;
    }
}
@media (min-width: 768px) and (max-width: 800px) {
    .header_social_links_main_wrapper {
        max-width: 240px!important;
    }
    .header_search_form {
        max-width: 208px!important;
    }
    .header_search_input_buttons_wrapper {
        max-width: 290px!important;
    }
    .header_personal_area_btn {
        width: 30px!important;
        height: 30px!important;
    }
    .header_mode_btn {
        width: 30px!important;
        height: 30px!important;
    }
    .header_personal_area_btn img {
        width: 30px!important;
        height: 30px!important;
    }
    .header_mode_btn  img{
        width: 22px!important;
        height: 22px!important;
    }
    .header_search_form_input_field {
        font-size: 12px!important;
        width: 82% !important;
    }
}


@media (min-width: 426px) and (max-width: 768px) {
    .header_search_form {
        max-width: 400px!important;
        margin: 0 auto;
    }
}
@media (max-width: 426px) {
    .header_search_form {
        max-width: 300px!important;
        margin: 0 auto;
    }
}

.vk_header_social_link {
    background: #0077FF;
}
.youtube_header_social_link {
    background: #E71E24;
}
.telegram_header_social_link {
 background: #0088CC;

}
.dzen_header_social_link {
    background: #333333;

}

.headers_navigation_link:hover {
    color: #E71E24;
}
.headers_navigation_link2:hover {
    color: #E71E24;
    text-decoration: underline;
}

.header_dropdown_wrapper:hover .header_dropdown_title {
    color: #E71E24;
    border-bottom: 1px solid #E71E24;
}
.header_dropdown_wrapper:hover .header_dropdown_title2 {
    color: #E71E24;
    border-bottom: 1px solid #E71E24;
}


