@font-face {
    font-family: 'Evolventa';
    src: url('../fonts/Evolventa-Bold.eot');
    src: url('../fonts/Evolventa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Evolventa-Bold.woff2') format('woff2'),
    url('../fonts/Evolventa-Bold.woff') format('woff'),
    url('../fonts/Evolventa-Bold.ttf') format('truetype'),
    url('../fonts/Evolventa-Bold.svg#Evolventa-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Regular.eot');
    src: url('../fonts/Rawline-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Regular.woff2') format('woff2'),
    url('../fonts/Rawline-Regular.woff') format('woff'),
    url('../fonts/Rawline-Regular.ttf') format('truetype'),
    url('../fonts/Rawline-Regular.svg#Rawline-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Bold.eot');
    src: url('../fonts/Rawline-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Bold.woff2') format('woff2'),
    url('../fonts/Rawline-Bold.woff') format('woff'),
    url('../fonts/Rawline-Bold.ttf') format('truetype'),
    url('../fonts/Rawline-Bold.svg#Rawline-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rawline';
    src: url('../fonts/Rawline-Medium.eot');
    src: url('../fonts/Rawline-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Rawline-Medium.woff2') format('woff2'),
    url('../fonts/Rawline-Medium.woff') format('woff'),
    url('../fonts/Rawline-Medium.ttf') format('truetype'),
    url('../fonts/Rawline-Medium.svg#Rawline-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




.main_wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.authorization {
    width: 100%;
    border-top: 0.8px solid #D4E4F2;
    padding-top: 120px;
    padding-bottom: 60px;
}

.authorization_wrapper {
    width: 100%;
    max-width: 518px;
    margin: 0 auto;
}
.authorization_main_title {
    text-align: center;
    color: #2F333A;
    font-family: 'Evolventa', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 35px;
}

.authorization_form_inputs_main_wrapper {
    border-radius: 6px;
    border: 1.5px solid rgba(82, 82, 82, 0.50);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 35px;
    padding: 25px 40px;
    background: #FFFFFF;
    position: relative;
}
.authorization_form_inputs_main_wrapper_active {
    border-radius: 6px;
    border: 1.5px solid #2F333A;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 35px;
    padding: 25px 40px;
    background: #FFFFFF;
    position: relative;
}
.authorization_form_input_field {
    width: 100%;
    border: none;
    outline: none;
    color: #2F333A;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    position: relative;
    background: none;
}
.authorization_form_input_field_title {
    color: #6F6F6F;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    /*bottom: 10px;*/
    transition: all 0.3s ease;
    left: 0;
}
.authorization_form_input_field_title2 {
    color: #6F6F6F;
    font-family: 'Rawline',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    /*bottom: 10px;*/
    transition: all 0.3s ease;
    left: 0;
}

.active {
    width: 90%;
    position: relative;
}

.active2 {
    width: 90%;
    position: relative;
}

.active .authorization_form_input_field_title {
    transform: translateY(-50px);
    font-size: 14px!important;
    background: #FFFFFF;
    padding: 10px 30px;
}

.active2 .authorization_form_input_field_title2 {
    transform: translateY(-50px);
    font-size: 14px!important;
    background: #FFFFFF;
    padding: 10px 30px;
}

.authorization_form_input_box_child {
    width: 90%;
    position: relative;
}

.authorization_form_enter_btn {
    width: 100%;
    border-radius: 6px;
    background: #E71E24;
    height: 78px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: 'Evolventa', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.4px;
    border: none;
}
.register_link {
    color: #7A7A7A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}
.forget_password_link {
    color: #7A7A7A;
    font-family: 'Rawline', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;
}

.login_with_social_links_items_main_wrapper {
    width: 100%;
}
.login_with_social_links_item_main_title {
    color: rgba(47, 51, 58, 0.85);
    font-family: 'Rawline', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.login_with_social_links_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.login_with_social_links_item {
    width: 48%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    border-radius: 6px;
    padding: 14px 21px;
    height: 68px;
}

.login_with_social_links_item:first-child {
    background: #07F;
}

.login_with_social_links_item:nth-child(2) {
    background: #08C;
}
.login_with_social_links_item:nth-child(3) {
    background: #D61E3B;
}
.login_with_social_links_item:last-child {
    background: #005FF9;
}

.login_with_social_links_item_title {
    color: #FFF;
    font-family: 'Evolventa',sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    margin-left: 33px;
}


/*media*/

@media (min-width: 426px) and (max-width: 768px) {
    .authorization_main_title {
        font-size: 27px!important;
        margin-bottom: 25px!important;
    }
    .authorization {
        padding-top: 90px!important;
        padding-bottom: 40px!important;
    }
    .authorization_form_inputs_main_wrapper {
        padding: 20px 30px!important;
        margin-bottom: 25px!important;
    }
    .authorization_form_inputs_main_wrapper_active {
        padding: 20px 30px!important;
        margin-bottom: 25px!important;
    }
    .authorization_form_enter_btn {
        font-size: 16px!important;
        margin-bottom: 15px!important;
        height: 55px!important;
    }
    .register_link {
        font-size: 17px!important;
    }
    .login_with_social_links_item_main_title {
        font-size: 20px!important;
    }
    .login_with_social_links_item {
        height: 52px!important;
        margin-bottom: 15px!important;
        padding: 13px 15px!important;

    }
    .login_with_social_links_item_title {
        font-size: 17px!important;
        margin-left: 18px!important;
    }
   .login_with_social_links_item:first-child .login_with_social_links_item_icon svg {
        width: 28px!important;
        height: 18px!important;
    }
    .login_with_social_links_item:nth-child(2) .login_with_social_links_item_icon svg {
        width: 30px!important;
        height: 25px!important;
    }
    .login_with_social_links_item:nth-child(3) .login_with_social_links_item_icon svg {
        width: 33px!important;
        height: 30px!important;
    }
    .login_with_social_links_item:last-child .login_with_social_links_item_icon svg {
        width: 30px!important;
        height: 28px!important;
    }
    .authorization_form_input_field_icon svg {
        height: 25px!important;
        width: 25px!important;
    }
    .authorization_form_input_field_title {
        font-size: 17px!important;
        left: 30px!important;
    }
    .authorization_form_input_field {
        font-size: 17px!important;
    }
}
@media  (max-width: 426px) {
    .authorization_form_input_field {
        font-size: 14px!important;
    }
    .authorization_form_input_field_title {
        font-size: 14px!important;
        left: 22px!important;
    }
    .authorization_form_input_field_icon svg {
        height: 20px!important;
        width: 20px!important;
    }
    .login_with_social_links_item:last-child .login_with_social_links_item_icon svg {
        width: 20px!important;
        height: 20px!important;
    }
    .login_with_social_links_item:first-child .login_with_social_links_item_icon svg {
        width: 21px!important;
        height: 13px!important;
    }
    .login_with_social_links_item:nth-child(2) .login_with_social_links_item_icon svg {
        width: 20px!important;
        height: 17px!important;
    }
    .login_with_social_links_item:nth-child(3) .login_with_social_links_item_icon svg {
        width: 24px!important;
        height: 24px!important;
    }
    .login_with_social_links_item_title {
        font-size: 14px!important;
        margin-left: 10px!important;
    }
    .login_with_social_links_item {
        height: 40px!important;
        margin-bottom: 12px!important;
        padding: 12px 13px!important;
    }
    .login_with_social_links_item_main_title {
        font-size: 18px!important;
        margin-bottom: 17px!important;
    }
    .register_link {
        font-size: 14px!important;
    }
    .authorization_form_enter_btn {
        font-size: 12px!important;
        margin-bottom: 11px!important;
        height: 45px!important;
    }
    .authorization_form_inputs_main_wrapper {
        padding: 14px 22px!important;
        margin-bottom: 20px!important;

    }
    .authorization_form_inputs_main_wrapper_active {
        padding: 14px 22px!important;
        margin-bottom: 20px!important;

    }
    .authorization_main_title {
        font-size: 25px!important;
        margin-bottom: 15px!important;
    }
    .authorization {
        padding-top: 60px!important;
        padding-bottom: 30px!important;
    }
}

@media (max-width: 768px) {
    .authorization_wrapper {
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
}
